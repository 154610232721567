import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginContainer = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: #000;
`;

export const LoginWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;

export const LoginIconContainer = styled.div`
  margin: 16px 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin: 8px 16px;
  }
`;

export const LoginIcon = styled(Link)``;

export const LoginContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const LoginForm = styled.form`
  background: #d3b456;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 26px 32px;
  border-radius: 10px;

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;

export const ResetForm = styled.form`
  width: 100%;
  background: #d3b456;
  z-index: 1;
  display: grid;
  border-radius: 10px;
  padding: 26px;
`;

export const LoginFormH1 = styled.h1`
  margin-bottom: 36px;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

export const LoginFormLabel = styled.label`
  margin-bottom: 8px;
  margin-left: 10px;
  color: #000;
  font-weight: 600;
  font-size: 14px;
`;

export const LoginErrors = styled.p`
  color: #df0027;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginFormInput = styled.input`
  padding: 14px 14px;
  margin-bottom: 32px;
  background: white;
  border: none;
  border-radius: 10px;
`;

export const LoginFormButton = styled.button`
  background: #000;
  padding: 12px 0;
  margin-bottom: 24px;
  margin-top: 8px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export const LoginPasswordButton = styled.a`
  color: #000;
  display: flex;
  border: none;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
`;
