import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import fire from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../images/logos/logo_small.png";
import {
  LoginContainer,
  LoginFormButton,
  LoginFormInput,
  LoginWrap,
  LoginIcon,
  LoginContent,
  LoginForm,
  LoginFormH1,
  LoginFormLabel,
  LoginIconContainer,
  LoginPasswordButton,
  LoginErrors,
  ResetForm,
} from "./loginElements";

const Login = () => {
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleLogin = async (email, password) => {
    try {
      await fire.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      toast.error("Emailadres of wachtwoord komen niet overeen");
    }
  };

  const handleResetPassword = async (password) => {
    fire
      .auth()
      .sendPasswordResetEmail(password)
      .then(function () {
        toast.success("Er werd een bericht verstuurd naar uw emailadres");
      })
      .catch(function () {
        toast.error("Het opgegeven emailadres werd niet teruggevonden");
      });
  };

  const handleContentChange = () => {
    setOpenResetPassword(!openResetPassword);
    setOpenLogin(!openLogin);
  };

  const loginValidation = Yup.object().shape({
    email: Yup.string()
      .email("Geef een geldig emailadres op")
      .required("Emailadres is een verplicht veld"),
    password: Yup.string().required("Wachtwoord is een verplicht veld"),
  });

  const passwordResetValidation = Yup.object().shape({
    passwordReset: Yup.string()
      .email("Geef een geldig emailadres op")
      .required("Emailadres is een verplicht veld"),
  });

  return (
    <>
      <LoginContainer>
        <LoginWrap>
          <LoginIconContainer>
            <LoginIcon to="/">
              <img src={logo} height={80} width={112} alt="The Crowd" />
            </LoginIcon>
          </LoginIconContainer>
          <LoginContent>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={loginValidation}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleLogin(values.email, values.password);
                  actions.setSubmitting(false);
                  actions.resetForm();
                }, 1500);
              }}
            >
              {(props) => (
                <LoginForm onSubmit={props.handleSubmit}>
                  <LoginFormH1>Inloggen</LoginFormH1>
                  <LoginFormLabel htmlFor="email">
                    {props.errors.email && props.touched.email ? (
                      <LoginErrors>{props.errors.email}</LoginErrors>
                    ) : (
                      "Emailadres"
                    )}
                  </LoginFormLabel>
                  <LoginFormInput
                    type="email"
                    name="email"
                    placeholder="Emailadres..."
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    onChange={props.handleChange}
                  />
                  <LoginFormLabel htmlFor="password">
                    {props.errors.password && props.touched.password ? (
                      <LoginErrors>{props.errors.password}</LoginErrors>
                    ) : (
                      "Wachtwoord"
                    )}
                  </LoginFormLabel>
                  <LoginFormInput
                    type="password"
                    name="password"
                    placeholder="Wachtwoord..."
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    onChange={props.handleChange}
                  />

                  <LoginFormButton type="submit" disabled={props.isSubmitting}>
                    {props.isSubmitting ? "Even geduld..." : "Doorgaan"}
                  </LoginFormButton>
                  <LoginPasswordButton onClick={handleContentChange}>
                    Wachtwoord vergeten?
                  </LoginPasswordButton>
                </LoginForm>
              )}
            </Formik>
            <Modal
              open={openResetPassword}
              onClose={handleContentChange}
              center
              showCloseIcon={false}
            >
              <Formik
                initialValues={{ passwordReset: "" }}
                validationSchema={passwordResetValidation}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    handleResetPassword(values.passwordReset);
                    handleContentChange();
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }, 1500);
                }}
              >
                {(props) => (
                  <ResetForm onSubmit={props.handleSubmit}>
                    <LoginFormH1>Wachtwoord vergeten?</LoginFormH1>
                    <LoginFormLabel htmlFor="passwordReset">
                      {props.errors.passwordReset ? (
                        <LoginErrors>{props.errors.passwordReset}</LoginErrors>
                      ) : (
                        "Emailadres"
                      )}
                    </LoginFormLabel>
                    <LoginFormInput
                      type="email"
                      name="passwordReset"
                      placeholder="Emailadres..."
                      onBlur={props.handleBlur}
                      value={props.values.passwordReset}
                      onChange={props.handleChange}
                    />

                    <LoginFormButton
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      {props.isSubmitting ? "Even geduld..." : "Doorgaan"}
                    </LoginFormButton>
                    <LoginPasswordButton onClick={handleContentChange}>
                      Terug naar inloggen?
                    </LoginPasswordButton>
                  </ResetForm>
                )}
              </Formik>
            </Modal>
          </LoginContent>
        </LoginWrap>
      </LoginContainer>
      <ToastContainer />
    </>
  );
};

export default Login;
