import React from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavButton,
  NavButtonLink,
} from "./navbarElements";
import logo from "../../images/logos/logo_TGSW_2.png";

export const NavbarSympho = ({ handleIsOpen }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav id="navbar_classic" scrollNav={true.toString()}>
        <NavbarContainer>
          <NavLogo to="/symphonic" onClick={toggleHome}>
            <img
              src={logo}
              height={80}
              width={112}
              alt="The Crowd Goes Classic"
            />
          </NavLogo>
          <MobileIcon onClick={handleIsOpen}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="info"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Info
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="sponsorsInfo"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Sponsors
              </NavLinks>
            </NavItem>
            <NavButton>
              <NavButtonLink to="/login">Login</NavButtonLink>
            </NavButton>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavbarSympho;
