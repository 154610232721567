import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import fire from "../firebase";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function useConcertsData() {
  const [concertsData, setConcertsData] = useState([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("concerts")
      .orderBy("startDate", "asc")
      .onSnapshot((snapshot) => {
        const newConcerts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConcertsData(newConcerts);
      });
  }, []);

  return concertsData;
}

export function useStavazaData() {
  const [concertsData, setConcertsData] = useState([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("stavaza")
      .orderBy("startDate", "asc")
      .onSnapshot((snapshot) => {
        const newConcerts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConcertsData(newConcerts);
      });
  }, []);

  return concertsData;
}

export function useCalendarData() {
  const [calenderData, setCalenderData] = useState([]);

  useEffect(() => {
    fire
      .firestore()
      .collection("calendar")
      .onSnapshot((snapshot) => {
        const newCalendar = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCalenderData(newCalendar);
      });
  }, []);
  return calenderData;
}

export const loadImages = (image) => {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = image.src;
    loadImg.onload = () => resolve(image.src);
    loadImg.onerror = (err) => reject(err);
  });
};

export function formattedDate(date) {
  const newD = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  return newD;
}

export function formattedTime(date) {
  if (date === undefined) {
    return "";
  } else {
    const newD = new Date(date * 1000);
    return newD;
  }
}
