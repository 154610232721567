import styled from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export const Button = styled(LinkScroll)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#d3b456" : "#0c0c0c")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ primary }) => (primary ? "#0c0c0c" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "transparent" : "#d3b456")};
    color: ${({ primary }) => (primary ? "#d3b456" : "transparent")};
  }
`;

export const ButtonToLink = styled(LinkRouter)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#d3b456" : "#0c0c0c")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ primary }) => (primary ? "#0c0c0c" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "transparent" : "#d3b456")};
    color: ${({ primary }) => (primary ? "#d3b456" : "transparent")};
  }
`;

export const ButtonToExterLink = styled.a`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#d3b456" : "#0c0c0c")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ primary }) => (primary ? "#0c0c0c" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "transparent" : "#d3b456")};
    color: ${({ primary }) => (primary ? "#d3b456" : "transparent")};
  }
`;

export const ButtonToExterLinkConcerts = styled.a`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "#d3b456" : "#0c0c0c")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "3px 9px")};
  color: ${({ primary }) => (primary ? "#0c0c0c" : "#fff")};
  font-size: ${({ fontSmall }) => (fontSmall ? "1rem" : "17px")};
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-left: 8px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "transparent" : "#d3b456")};
    color: ${({ primary }) => (primary ? "#d3b456" : "transparent")};
  }

  @media screen and (max-width: 480px) {
    font-size: ${({ fontSmall }) => (fontSmall ? "0.75rem" : "17px")};
  }
`;
