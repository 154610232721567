import logo_symphonic from "../../images/logos/logo_TCGS.png";
import contact_undraw from "../../images/icons/contact.svg";
import camera_undraw from "../../images/icons/camera.svg";
import merchJPG from "../../images/icons/merchandise.jpg";
import affiche from "../../images/icons/affiche.jpg";

export const Symphonic = {
  id: "symphonic",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "The Crowd Goes Symphonic",
  headLine: "7 mei 2022 - Heule",
  description: "Classic meets rock",
  buttonLabel: "Klik voor meer info",
  imgStart: false,
  img: logo_symphonic,
  alt: "The Crowd Goes Symphonic",
  dark: false,
  primary: true,
  buttonLink: true,
  buttonPath: "/symphonic",
  target: "_blank",
  darkText: false,
};

export const Contact = {
  id: "contact",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Vragen? Info? Prijsbestek?",
  headLine: "Contacteer ons",
  description: "Betaalbaar en professioneel. Twijfel niet!",
  buttonLabel: "Stuur ons een bericht",
  imgStart: true,
  img: contact_undraw,
  alt: "Contact",
  dark: false,
  primary: true,
  buttonLink: false,
  darkText: false,
  href: "mailto: info@thecrowd.be",
};

export const Merchandise = {
  id: "merchandise",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: `Hoodie "the crowd" bestellen?`,
  headLine: "Merchandise",
  customDesc2: `Klik op "Hoodie bestellen" en geef uw gegevens door (zie meer info) !`,
  buttonLabel: "Hoodie bestellen",
  buttonLabel2: "Meer info",
  href2: `mailto: info@thecrowd.be?subject=Hoodie "The Crowd"`,
  imgStart: false,
  img: merchJPG,
  alt: "merchandise",
  dark: false,
  primary: true,
  buttonLink: false,
  darkText: false,
  reversed: false,
  secondBtn: true,
  href: `mailto: info@thecrowd.be?subject=Hoodie "The Crowd"`,
};

export const PromoMaterialObj = {
  id: "promo",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  noTopline: true,
  customDesc: true,
  description: `Je kan deze template (zwart/wit of kleur) importeren in Paint of
  Word of een grafisch programma om jouw informatie toe te voegen.
  Vermeld datum, uur, plaats, prijs, organisatie,…in een tekstvak
  bovenop deze template.`,
  buttonLabel: "Templates downloaden",
  imgStart: false,
  img: affiche,
  alt: "promo",
  dark: false,
  primary: true,
  buttonLink: false,
  darkText: false,
  reversed: false,
  downloadBtn: true,
};

export const Records = {
  id: "records",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Link naar alle audio-opnames van de live-concerten",
  headLine: "Records",
  description:
    "Klik op onderstaande knop om door te gaan naar het overzicht van de opnames",
  buttonLabel: "Naar Google Drive",
  imgStart: false,
  img: camera_undraw,
  alt: "Records",
  dark: false,
  primary: true,
  buttonLink: false,
  darkText: false,
  href: "https://drive.google.com/drive/folders/1nW4iqGmU4Nq35qtzPO21qT4ixJdkBXOA",
  target: "_blank",
  marginTop: false,
};
