import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

export const FooterContainer = styled.footer`
  background: ${({ darkBg }) => (darkBg ? "#000000" : "#0c0c0c")};
`;

export const FooterWrap = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(LinkScroll)`
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const WebsiteRights = styled.small`
  color: #f3f1f2;

  @media screen and (max-width: 820px) {
    margin: 12px 0px 24px 0px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;
export const SocialIconLink = styled.a`
  color: #f3f1f2;
  align-items: center;
  display: flex;
  font-size: 30px;

  &:hover {
    color: #d3b456;
  }
`;
