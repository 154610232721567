import React from "react";

import {
  MediaContainer,
  MediaWrapper,
  MediaH1,
  MediaCard,
  LinkWrapper,
} from "./mediaElements";

import { MemberLink } from "../Members/membersElements";
import ImportGallery from "../importGallery";

const Media = ({ images }) => {
  return (
    <>
      <MediaContainer id="media">
        <MediaWrapper>
          <MediaH1>Media</MediaH1>
          <LinkWrapper>
            Kijk&nbsp;
            <MemberLink
              href="https://music.youtube.com/channel/UCtccMKgO7meZjj3w2006oHw"
              target="_blank"
            >
              hier
            </MemberLink>
            &nbsp;naar een aantal recente live-videoclips
          </LinkWrapper>
          <MediaCard>
            <ImportGallery images={images} maxRows={4} />
          </MediaCard>
        </MediaWrapper>
      </MediaContainer>
    </>
  );
};

export default Media;
