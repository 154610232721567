import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavButton,
  NavButtonLink,
} from "./navbarElements";
import logo from "../../images/logos/logo_small.png";

const Navbar = ({ handleIsOpen }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  // SYMPHONIC ONDER REFERENCES
  /* <NavItem>
      <NavLinks
        to="symphonic"
        smooth={true}
        duration={900}
        spy={true}
        exact="true"
        offset={-80}
      >
        Symphonic
      </NavLinks>
    </NavItem> */

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <>
      <Nav id="navbar" scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img src={logo} height={80} width={112} alt="The Crowd" />
          </NavLogo>
          <MobileIcon onClick={handleIsOpen}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="members"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Members
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="concerts"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Concerts
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="promo"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Promo
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="media"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Media
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="references"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                References
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="merchandise"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Merchandise
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Contact
              </NavLinks>
            </NavItem>
            <NavButton>
              <NavButtonLink to="/login">Login</NavButtonLink>
            </NavButton>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
