import styled from "styled-components";

export const ConcertsContainer = styled.div`
  color: #fff;

  margin-top: ${({ marginTop }) => (marginTop ? "80px" : "0px")};
  background: ${({ lightBg }) => (lightBg ? "#0c0c0c" : "#000")};

  @media screen and (max-width: 768px) {
    padding: 50px 0px 50px 0px;
  }
`;

export const ConcertsCard = styled.div`
  border-style: solid;
  border-color: #acacac;
  background: ${({ isWhite }) => (isWhite ? "white" : "transparent")};
  color: ${({ isBlack }) => (isBlack ? "black" : "transparent")};
  border-width: thin;
  border-radius: 10px;
  margin: ${({ noMargin }) => (noMargin ? "0px" : "45px 0px 45px 0px")};
  padding: 15px 15px 15px 15px;
  width: 100%;
  max-width: 1100px;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const ConcertsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #d3b456;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 1100px;
`;

export const ConcertsListItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: 100%;
  flex: 1;
`;

export const ConcertsListDate = styled.div`
  flex-basis: 100%;
  color: #d3b456;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ConcertsListItemText = styled.p`
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 600;
  color: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;


  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ConcertsBottomListItem = styled.div`
  display: flex;
  flex-direction: row;
  color: #acacac;
  font-size: 16px;
  margin-top: 12px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ConcertsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0px 30px 0px;
`;

export const ConcertsDeleteButton = styled.button`
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: grab;
`;

export const ConcertsAddButton = styled.button`
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: grab;
`;

export const ConcertsListItemTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConcertsListItemPlace = styled.div`
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
`;

export const ConcertsWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
`;

export const CustomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
`;

export const ConcertsH1 = styled.h1`
font-size: 2.5rem;
color: #acacac;
margin: 64px 0px 0px 0px;

@media screen and (max-width: 768px) {
  margin: 0px 0px 0px 0px;
}

@media screen and (max-width: 480px) {
  font-size: 2rem;
  margin-top: 0px;
}
`;

export const ConcertsH2 = styled.h2`
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 45px;
  margin-top: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;
`;

export const ConcertsP = styled.p`
  font-size: 12px;
  color: #0c0c0c;
`;

export const ConcertsFormLabel = styled.label`
  margin-bottom: 8px;
  margin-left: 10px;
  color: #acacac;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const ConcertsErrors = styled.p`
  color: #df0027;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConcertsFormInput = styled.input`
  padding: 14px 14px;
  margin-bottom: 32px;
  width: 100%;
  background: white;
  border: none;
  border-radius: 10px;
`;

export const ConcertsFormSelect = styled.select`
  padding: 14px 14px;
  margin-bottom: 32px;
  width: 100%;
  background: white;
  border: none;
  border-radius: 10px;
`;

export const ConcertsFormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const ConcertsFormButton = styled.button`
  border-radius: 50px;
  background: #d3b456;
  white-space: nowrap;
  padding: 10px 20px;
  color: black;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: grab;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: transparent;
    color: #d3b456;
  }
`;
