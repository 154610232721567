import React from "react";

import media1 from "../../images/references/REF_1.PNG";

import {
  GlobalContainer,
  GlobalWrapper,
  GlobalH1,
  GlobalCard,
} from "../GlobalElements";
import {
  ReferencesTextContainer,
  ReferencesUL,
  ReferencesLI,
  ReferencesGalleryContainer,
  TitleContainer,
  TitleH2,
  LinkWrapper,
} from "./referencesElement";
import ImportGallery from "../importGallery";
import { MemberLink } from "../Members/membersElements";

const References = ({ images }) => {
  return (
    <>
      <GlobalContainer id="references" lightBg>
        <GlobalWrapper>
          <GlobalH1>References</GlobalH1>
          <img
            src={media1}
            height={"auto"}
            width={"auto"}
            alt="ref_IMG"
            className="ref_img"
          />
          <GlobalCard>
            <TitleContainer>
              <TitleH2>Referentielijst “The crowd” door de jaren heen</TitleH2>
            </TitleContainer>
            <ReferencesTextContainer>
              <ReferencesUL>
                <ReferencesLI>
                  Gentse Feesten, waaronder op de Groentenmarkt (8x) en het
                  Sint-Baafsplein
                </ReferencesLI>
                <ReferencesLI>
                  Vijfhoekfeesten Tollembeek (afsluiter na Stan van Samang,
                  Urbanus en de Fanfaar en The Scabs)
                </ReferencesLI>
                <ReferencesLI>
                  “Symphonic meets Rock” met 80-koppig Houtlands Harmonieorkest
                  in Torhout
                </ReferencesLI>
                <ReferencesLI>
                  Grensrock Menen (afsluiter na Tom Robinson Band)
                </ReferencesLI>
                <ReferencesLI>
                  Midsummer Handzame (opener voor Kowlier, Gorki en De Kreuners)
                </ReferencesLI>
                <ReferencesLI>
                  Tinekesfeesten Heule (o.a. afsluiter na Mama’s Jasje,
                  afsluiter na Belle Perez, opener voor “Spelers en Drinkers”)
                </ReferencesLI>
                <ReferencesLI>
                  Internationaal evenement DEXIA GROUP – Crédit Local de France
                  in de zaal van L’arche de la Défense te Parijs. Huisorkest van
                  de DEXIA bank
                </ReferencesLI>
                <ReferencesLI>
                  Parkconcerten zowat overal in Vlaanderen (Olsene, Lede,
                  Geraardsbergen, Zottegem, Antwerpen, Westende, Gits,
                  Roeselare, Brouwerij Ename,…)
                </ReferencesLI>
                <ReferencesLI>
                  10x voor “Kom op tegen Kanker” i.o.v. “De Kromme Gedong” in
                  het Baljuwhuis te Galmaarden.
                </ReferencesLI>
                <ReferencesLI>5x op “Hasselt Live”</ReferencesLI>
                <ReferencesLI>4x op “Swinging Maaseik”</ReferencesLI>
                <ReferencesLI>2x op “Swinging Genk” </ReferencesLI>
                <ReferencesLI>2x op “Bilzen Live”</ReferencesLI>
                <ReferencesLI>4x op Nieuwjaarsfeest van de ACLVB</ReferencesLI>
                <ReferencesLI>
                  Bedrijfsfeesten voor o.a. Beiersdorf AG (Nivea), EMEA General
                  Electric INTERLOGIX (Brussel), NORTON anti-virus, ERM
                  Merelbeke, Penders Maasmechelen, Wilkinson American Movie Day
                  (Brussel én Mons), Greenhouse Dienstencheques (Antwerpen)
                </ReferencesLI>
                <ReferencesLI>
                  VIP-party’s voor o.a. aankomst Ronde van België in Knokke
                  (Casino), Feesten met Burgemeester Q (Kortrijk), Flanders Expo
                  Gent (na concert Eric Clapton), CLUB AB (BELFIUS)
                </ReferencesLI>
                <ReferencesLI>
                  Ontelbare Dorpsfeesten, Pensenkermissen en privéparty’s over
                  heel het Belgisch grondgebied
                </ReferencesLI>
              </ReferencesUL>
              <LinkWrapper>
                Kijk&nbsp;
                <MemberLink
                  href="https://music.youtube.com/channel/UCtccMKgO7meZjj3w2006oHw"
                  target="_blank"
                >
                  hier
                </MemberLink>
                &nbsp;naar een aantal recente live-videoclips van The Crowd
              </LinkWrapper>
              <ReferencesGalleryContainer>
                <ImportGallery images={images} maxRows={2} />
              </ReferencesGalleryContainer>
            </ReferencesTextContainer>
          </GlobalCard>
        </GlobalWrapper>
      </GlobalContainer>
    </>
  );
};

export default References;
