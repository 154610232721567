import React, { useState } from "react";
import { Modal } from "react-responsive-modal";

import "./modal.css";
import {
  GlobalContainer,
  GlobalCardModal,
  GlobalModalContainerSecond,
  BtnContainerModal,
  BtnModal,
} from "../GlobalElements";
import {
  CarouselTextWrapper,
  CarouselTextContainer,
} from "../Carousel/carouselElements";
import {
  TitleContainer,
  CustomH2,
  TitleH2Modal,
  ReferencesUL,
  ReferencesLI,
  ReferencesTextContainerModal,
} from "../References/referencesElement";
import {
  MemberLink,
  InfoClassicImg,
  MemberLinkButton,
} from "../Members/membersElements";

const InfoClassic = ({ images }) => {
  const [openModal, setOpenModal] = useState(false);
  const loadedImg = images.map((item) => item.src);

  return (
    <>
      <GlobalContainer id="info" noPadding marginTop>
        <CarouselTextContainer>
          <CarouselTextWrapper>
            <TitleContainer>
              <CustomH2>The Crowd Goes Symphonic</CustomH2>
            </TitleContainer>
            <div style={{ marginTop: "24px", marginBottom: "18px" }}>
              <InfoClassicImg
                src={loadedImg[1]}
                right={true.toString()}
                greyScale
                alt="InfoClassic1"
              />
              Het idee ontstond in januari 2015, toen The Crowd werd gevraagd
              door het Houtlands Harmonieorkest o.l.v. dirigent Nico Logghe om
              samen met Luc Devos (Gorki) deel te nemen aan hun Nieuwjaars show
              “Classic meets Rock”. Dat concert draaide uiteindelijk uit op een
              hommage aan de in november 2014 schielijk overleden zanger van
              Gorki.
              <br />
              <br />
              Claude Delatter had de smaak te pakken en zag daarin een
              uitdaging. In 2019 verscheen het artikel met de Titel : “Mijn
              droom? Optreden met een Symfonisch Orkest” in de Krant van
              West-Vlaanderen, de aanzet van dit groot avontuur i.s.m. zijn vzw
              De Kaaimannen.
            </div>
            <div style={{ marginBottom: "18px" }}>
              <InfoClassicImg
                src={loadedImg[0]}
                left={true.toString()}
                greyScale
                alt="InfoClassic2"
              />
              Oorspronkelijk gepland voor 27/08/2020 op het Lagaeplein te Heule,
              maar door COVID verplaatst naar 28/08/2021 en om dezelfde reden
              uiteindelijk gepland op 7 mei 2022 op een nog nader te bepalen
              locatie in Heule, belooft dit een totaalspektakel te worden, met
              niet minder dan 80 muzikanten op het podium, grote videoschermen
              met livebeelden en verschillende leuke verrassingen.
              <br />
              <br />
              The Crowd zal worden begeleid door het Houtlands Harmonieorkest
              o.l.v. Nico Logghe en neemt je mee op een verrassende tocht door
              het “Classic Rock programma” dat kenmerkend is voor The Crowd.
            </div>
            <div>
              <InfoClassicImg
                src={loadedImg[2]}
                right={true.toString()}
                greyScale
                alt="InfoClassic3"
              />
              Dit massaspektakel wordt live opgenomen, zowel wat betreft de
              klank als de diverse camera’s, om daarna gemonteerd te worden in
              een kwalitatieve DVD-box voor de “die hard fans”, maar uiteraard
              ook voor onze nieuwe fans. Een toekomstig collectors item.
              <br />
              <br />
              Kaarten reserveren kan vanaf 01/01/2022 via ons{" "}
              <MemberLink href="" target="_blank">
                online reservatieplatform
              </MemberLink>{" "}
              dat aan deze website zal worden gekoppeld.
              <br />
              <br />
              De organisatie van “The Crowd goes Symphonic” vraagt een zware
              financiële inspanning van vzw De Kaaimannen, dus elke sponsoring,
              hoe klein ook is meer dan welkom. Klik{" "}
              <MemberLinkButton onClick={() => setOpenModal(!openModal)}>
                hier
              </MemberLinkButton>{" "}
              voor een volledig sponsordossier.
            </div>
          </CarouselTextWrapper>
        </CarouselTextContainer>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(!openModal)}
          center
          showCloseIcon={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <GlobalModalContainerSecond>
            <GlobalCardModal>
              <TitleContainer>
                <TitleH2Modal>The Originals</TitleH2Modal>
              </TitleContainer>
              <ReferencesTextContainerModal>
                <ReferencesUL>
                  <ReferencesLI>
                    Uw bedrijfslogo op de hoofdpagina van de website “The Crowd
                    - Classic Coverband” met achter het logo een link naar uw
                    website
                  </ReferencesLI>
                  <ReferencesLI>Uw logo op onze Facebookpagina’s</ReferencesLI>
                  <ReferencesLI>
                    Uw logo op de HERAS-doeken die ter promotie op belangrijke
                    plaatsen zullen geplaatst worden vanaf 01/07/2020
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op alle affiches (in formaat A1, A2 en A3)
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op de inkomkaarten en drankenkaarten
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op volledige schermgrootte geprojecteerd voor het
                    concert, tijdens de pauze en na het concert
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op de hoes van de concert-DVD die achteraf wordt
                    uitgebracht
                  </ReferencesLI>
                  <ReferencesLI>
                    2 VIP-arrangementen ter waarde van € 80,- elk, voor de
                    VIP-AREA met VIP-receptie en de “all-in VIP BAR” vanaf 18u30
                  </ReferencesLI>
                  <ReferencesLI>
                    Voorrang bij de aankoop van extra VIP-arrangementen voor uw
                    eventuele genodigden tot 15/06/2020
                  </ReferencesLI>
                </ReferencesUL>
                <BtnContainerModal>
                  <BtnModal href="mailto:info@thecrowd.be?subject=The Crowd Goes Classic: sponsorformule 'The Originals'">
                    Ik heb interesse in "The Originals"
                  </BtnModal>
                </BtnContainerModal>
                <div
                  style={{
                    fontWeight: "bolder",
                    color: "#de0027",
                    textAlign: "center",
                  }}
                >
                  Deze formule kost u € 500,- exclusief BTW (en inclusief €
                  160,- VIP arrangement), waarvoor u een factuur ontvangt
                </div>
              </ReferencesTextContainerModal>
            </GlobalCardModal>
            <GlobalCardModal>
              <TitleContainer>
                <TitleH2Modal>The Heartbeat</TitleH2Modal>
              </TitleContainer>
              <ReferencesTextContainerModal>
                <ReferencesUL>
                  <ReferencesLI>
                    Uw bedrijfslogo op de hoofdpagina van de website “The Crowd
                    - Classic Coverband” met achter het logo een link naar uw
                    website
                  </ReferencesLI>
                  <ReferencesLI>Uw logo op onze Facebookpagina’s</ReferencesLI>
                  <ReferencesLI>
                    Uw logo op alle affiches (in formaat A1, A2 en A3)
                  </ReferencesLI>
                  <ReferencesLI>Uw logo op de drankenkaarten</ReferencesLI>
                  <ReferencesLI>
                    Uw logo samen met 3 andere sponsors op ¼ schermgrootte
                    geprojecteerd voor het concert, tijdens de pauze en na het
                    concert
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op de hoes van de concert-DVD die achteraf wordt
                    uitgebracht
                  </ReferencesLI>
                  <ReferencesLI>
                    2 Inkomtickets voor het Concert ter waarde van € 15,- elk
                  </ReferencesLI>
                </ReferencesUL>
                <BtnContainerModal>
                  <BtnModal href="mailto:info@thecrowd.be?subject=The Crowd Goes Classic: sponsorformule 'The Heartbeat'">
                    Ik heb interesse in "The Heartbeat"
                  </BtnModal>
                </BtnContainerModal>
                <div
                  style={{
                    fontWeight: "bolder",
                    color: "#de0027",
                    textAlign: "center",
                  }}
                >
                  Deze formule kost u € 170,- exclusief BTW (en inclusief € 30,-
                  inkomtickets), waarvoor u een factuur ontvangt
                </div>
              </ReferencesTextContainerModal>
            </GlobalCardModal>
            <GlobalCardModal>
              <TitleContainer>
                <TitleH2Modal>The Key</TitleH2Modal>
              </TitleContainer>
              <ReferencesTextContainerModal>
                <ReferencesUL>
                  <ReferencesLI>
                    Uw bedrijfslogo op de hoofdpagina van de website “The Crowd
                    - Classic Coverband” zonder een link naar uw website
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op alle affiches (in alle formaten)
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo op de achterkant van de inkomkaarten en
                    drankenkaarten
                  </ReferencesLI>
                  <ReferencesLI>
                    Uw logo samen met 7 andere “KEY”-sponsors op 1/8
                    schermgrootte geprojecteerd voor het concert, tijdens de
                    pauze en na het concert
                  </ReferencesLI>
                </ReferencesUL>
                <BtnContainerModal>
                  <BtnModal href="mailto:info@thecrowd.be?subject=The Crowd Goes Classic: sponsorformule 'The Key'">
                    Ik heb interesse in "The Key"
                  </BtnModal>
                </BtnContainerModal>
                <div
                  style={{
                    fontWeight: "bolder",
                    color: "#de0027",
                    textAlign: "center",
                  }}
                >
                  Deze formule kost u € 75- exclusief BTW waarvoor u een factuur
                  ontvangt
                </div>
              </ReferencesTextContainerModal>
            </GlobalCardModal>
            <GlobalCardModal>
              <TitleContainer>
                <TitleH2Modal>The Crew</TitleH2Modal>
              </TitleContainer>
              <ReferencesTextContainerModal>
                <div
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  Elk concert heeft zijn logistieke noden, zowel in de aanloop
                  naar het concert, als de dag voor, van en na het concert.
                  Concreet gaat het over de volgende zaken:
                </div>
                <ReferencesUL>
                  <ReferencesLI>
                    Er moeten badges gemaakt worden voor alle muzikanten,
                    crewleden en alle types medewerkers
                  </ReferencesLI>
                  <ReferencesLI>
                    Er is nood aan publiciteit in kranten, magazines, regionale
                    televisie
                  </ReferencesLI>
                  <ReferencesLI>
                    Een heftruck tijdens de opbouw en afbouw is handig
                  </ReferencesLI>
                  <ReferencesLI>
                    Een trits houten balken en planken voor de uitbouw van het
                    podium (10m op 14m vast) en/of catwalk zal nodig zijn
                  </ReferencesLI>
                  <ReferencesLI>
                    Er is nood aan toiletwagens, toiletpapier en/of “pispalen”
                    (zij het dan wel letterlijk)
                  </ReferencesLI>
                  <ReferencesLI>
                    Er zijn broodjes en maaltijden nodig voor zowat honderd
                    muzikanten, crewleden en medewerkers
                  </ReferencesLI>
                  <ReferencesLI>
                    Er is nood aan 200 flesjes water van 0.5L voor alle
                    podiummuzikanten, tijdens het eerste en tweede deel van het
                    concert, al dan niet met koelkast
                  </ReferencesLI>
                  <ReferencesLI>
                    Er zijn 80 comfortabele podiumstoelen nodig voor het
                    begeleidend Houtlands- Harmonieorkest
                  </ReferencesLI>
                  <ReferencesLI>
                    Er is een backstage gemeubelde kleedkamer nodig voor The
                    Crowd
                  </ReferencesLI>
                  <ReferencesLI>Een aantal bossen bloemen…</ReferencesLI>
                </ReferencesUL>
                <BtnContainerModal>
                  <BtnModal href="mailto:info@thecrowd.be?subject=The Crowd Goes Classic: sponsorformule 'The Crew'">
                    Ik heb interesse in "The Crew"
                  </BtnModal>
                </BtnContainerModal>
                <div
                  style={{
                    fontWeight: "bolder",
                    color: "#de0027",
                    textAlign: "center",
                  }}
                >
                  Deze formule is een formule waarbij u zorgt voor sponsoring in
                  natura, waarover onderling wordt overlegd welke voordelen
                  hieraan zullen gekoppeld worden, in verhouding tot het
                  bespaarde bedrag in ons uitgavenbudget
                </div>
              </ReferencesTextContainerModal>
            </GlobalCardModal>
          </GlobalModalContainerSecond>
        </Modal>
      </GlobalContainer>
    </>
  );
};

export default InfoClassic;
