import React from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import logo from "../../images/logos/logo_TGSW_2.png";

import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./footerElements";

const FooterSympho = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <FooterContainer darkBg>
        <FooterWrap>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to="/" onClick={toggleHome}>
                <img src={logo} height={80} width={112} alt="The Crowd" />
              </SocialLogo>
              <WebsiteRights>
                The Crowd Goes Classic - The Crowd Classic Coverband ©{" "}
                {new Date().getFullYear()} All rights reserved.
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/thecrowdcovergroep/"
                  target="_blank"
                  aria-label="Facebook"
                  rel="noopener"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://music.youtube.com/channel/UCtccMKgO7meZjj3w2006oHw"
                  target="_blank"
                  aria-label="Youtube"
                  rel="noopener"
                >
                  <FaYoutube />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default FooterSympho;
