import React from "react";

import { SponsorClassicImage } from "../Carousel/sponsorCarousel/sponsorElements";
import { GlobalContainer } from "../GlobalElements";
import ImportCarousel from "../importCarousel";

const SponsorsClassic = ({ images }) => {
  return (
    <>
      <GlobalContainer id="sponsorsInfo">
        <div style={{ padding: "16px", cursor: "grab" }}>
          <ImportCarousel
            interval={4000}
            images={images.map((item, key) => (
              <SponsorClassicImage key={key} src={item.src} alt={item.alt} />
            ))}
          />
        </div>
      </GlobalContainer>
    </>
  );
};

export default SponsorsClassic;
