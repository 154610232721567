import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function ImportCarousel({
  showIndicators,
  onClickItem,
  images,
  interval,
  showArrows,
}) {
  return (
    <Carousel
      showIndicators={showIndicators ? showIndicators : false}
      showStatus={false}
      showArrows={showArrows ? showArrows : false}
      showThumbs={false}
      stopOnHover={false}
      infiniteLoop
      autoPlay
      emulateTouch
      onClickItem={onClickItem}
      interval={interval}
    >
      {images}
    </Carousel>
  );
}
