import React from "react";
import { IoMdClock } from "react-icons/io";
import { FaMapPin } from "react-icons/fa";

import {
  MediaContainer,
  MediaWrapper,
  MediaH1,
  LinkWrapperCustom,
} from "../Media/mediaElements";

import InfoSection from "../InfoSection";
import { PromoMaterialObj } from "../InfoSection/Data";
import {
  CarouselTextContainer,
  CarouselTextWrapperLeft,
} from "../Carousel/carouselElements";
import {
  ConcertsList,
  ConcertsListItem,
  ConcertsListDate,
  ConcertsListItemText,
  ConcertsListItemTime,
  ConcertsListItemPlace,
  ConcertsBottomListItem,
} from "../Concerts/concertsElements";
import { ButtonToExterLinkConcerts } from "../ButtonElement";
import { MemberLink } from "../Members/membersElements";

const PromoMaterial = ({ data }) => {
  const filterData = (data) => {
    return data?.filter((i) => i.sponsorLink !== "")?.slice(0, 1);
  };

  return (
    <>
      <MediaContainer id="promo" lightBg>
        <MediaWrapper>
          <MediaH1>Promotiemateriaal</MediaH1>

          <CarouselTextContainer>
            <CarouselTextWrapperLeft>
              Download een template om je eigen affiche te ontwerpen en leg een
              link naar je online ticket-verkoop
              <br />
              <br />
              <LinkWrapperCustom>
                Bij verkoop van online tickets via een website of
                facebookpagina, stuur ons ook de link ervan door op&nbsp;
                <MemberLink href="mailto: info@thecrowd.be">
                  info@thecrowd.be
                </MemberLink>
                , zodat we die kunnen toevoegen onder de knop “info” in onze
                concertlijst.
              </LinkWrapperCustom>
              <br />
              Je kan hieronder een voorbeeld zien
            </CarouselTextWrapperLeft>
          </CarouselTextContainer>
          {filterData(data)?.map((item, key) => (
            <ConcertsList key={key}>
              <ConcertsListItem>
                <ConcertsListDate>
                  <div>
                    {item.startDate.toDate().toLocaleDateString("nl-NL", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </div>
                  {item.sponsorLink === "" ? null : (
                    <ButtonToExterLinkConcerts
                      href={item.sponsorLink}
                      target="_blank"
                      primary={true.toString()}
                    >
                      INFO
                    </ButtonToExterLinkConcerts>
                  )}
                </ConcertsListDate>
                <ConcertsListItemText>
                  {item.name === "" ? "-" : item.name}
                </ConcertsListItemText>
                <ConcertsBottomListItem>
                  <ConcertsListItemTime>
                    <div style={{ display: "block" }}>
                      <IoMdClock size={20} style={{ marginRight: "5px" }} />
                    </div>
                    {item.startTime === ""
                      ? "-"
                      : item.startTime.toDate().toLocaleTimeString("nl-NL", {
                          hour: "numeric",
                          minute: "numeric",
                        }) +
                        " - " +
                        item.endTime.toDate().toLocaleTimeString("nl-NL", {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                  </ConcertsListItemTime>
                  <ConcertsListItemPlace>
                    <div style={{ display: "block" }}>
                      <FaMapPin size={20} style={{ marginRight: "5px" }} />
                    </div>
                    {item.location === "" ? "-" : item.location}
                  </ConcertsListItemPlace>
                </ConcertsBottomListItem>
              </ConcertsListItem>
            </ConcertsList>
          ))}
        </MediaWrapper>
        <InfoSection {...PromoMaterialObj} />
      </MediaContainer>
    </>
  );
};

export default PromoMaterial;
