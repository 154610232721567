import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyA7b5oLYkcQ4YpJbd2Zpty8UxTF6r7umfg",
  authDomain: "the-crowd-coverband.firebaseapp.com",
  projectId: "the-crowd-coverband",
  storageBucket: "the-crowd-coverband.appspot.com",
  messagingSenderId: "208050314840",
  appId: "1:208050314840:web:960b1cb63a00fe7577342d",
  measurementId: "G-0FPF7GS9PM",
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
