import styled from "styled-components";

export const MemberContainer = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#0c0c0c" : "#000")};

  @media screen and (max-width: 768px) {
    padding: 50px 0px 50px 0px;
  }
`;

export const MemberWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const MemberCard = styled.div`
 border-style: solid;
  border-color: #acacac;
  border-width: thin;
  display: grid;
  border-radius: 10px;
  margin: 45px 0px 45px 0px;
  padding: 15px;
  width: 100%;
  max-width: 1100px;
  align-items: center; 

  @media screen and (max-width: 768px) {
    margin: 0;
    
`;

export const InfoClassicImg = styled.img`
  width: 40%;
  height: 100%;
  float: ${({ right }) => (right ? "right" : "left")};
  margin-left: ${({ right }) => (right ? "15px" : "0px")};
  margin-right: ${({ right }) => (right ? "0px" : "15px")};
  border-radius: 5px;
  filter: ${({ greyScale }) =>
    greyScale ? "grayscale(0%)" : "grayscale(100%)"};

  &:hover {
    filter: grayscale(100%);
    transition: all 1s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 18px;
  }
`;

export const MemberImg = styled.img`
  width: 45%;
  height: 45%;
  float: ${({ right }) => (right ? "right" : "left")};
  margin-left: ${({ right }) => (right ? "15px" : "0px")};
  margin-right: ${({ right }) => (right ? "0px" : "15px")};
  margin-bottom: 5px;
  border-radius: 10px;
  filter: ${({ greyScale }) =>
    greyScale ? "grayscale(100%)" : "grayscale(0%)"};

  &:hover {
    filter: grayscale(0%);
    transition: all 1s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 15px;
  }
`;

export const MemberImgContainer = styled.div`
  justify-content: center;
  padding-bottom: ${({ last }) => (last ? "15px" : "26px")};
  border-bottom-style: ${({ last }) => (last ? "none" : "solid")};
  border-bottom-color: ${({ last }) => (last ? "none" : "#acacac")};
  border-bottom-width: ${({ last }) => (last ? "none" : " thin")};
`;

export const MemberH1 = styled.h1`
  font-size: 2.5rem;
  color: #acacac;
  margin: 64px 0px 32px 0px;

  @media screen and (max-width: 768px) {
    margin: 0px 0px 64px 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${({ start }) => (start ? "36px" : "48px")};
  margin-bottom: 48px;
`;

export const MemberH2 = styled.h2`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const MemberP = styled.p`
  font-size: 16px;
  color: #cccc;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const MemberLink = styled.a`
  color: #cccc;

  &:hover {
    color: #d3b456;
  }
`;

export const MemberLinkButton = styled.button`
  color: #cccc;
  background: transparent;
  font-size: 16px;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: grab;
  text-decoration: underline;

  &:hover {
    color: #d3b456;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
