import React, { useState, Children, cloneElement } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import Moment from "react-moment";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";
import { MdArrowBack, MdInfoOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fire from "../../firebase";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal } from "react-responsive-modal";

import {
  GlobalH1,
  GlobalCalenderH2,
  GlobalText,
  GlobalModalContainer,
  GlobalModalH1,
} from "../GlobalElements";
import "./CalendarMobile.scss";
import {
  ConcertsCard,
  ConcertsContainer,
  CustomWrapper,
  ConcertsAddButton,
  ConcertsFormLabel,
  ConcertsFormSelect,
  ConcertsErrors,
  ConcertsFormButtonContainer,
  ConcertsFormButton,
  ConcertsButtonContainer,
} from "../Concerts/concertsElements";
import { useCalendarData } from "../../functions/general";

const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
  cloneElement(Children.only(children), {
    onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
    style: {
      className: `${children}`,
    },
  });

const CustomCalendar = () => {
  const [state, setState] = useState({
    openAdd: false,
    openDelete: false,
    openInfo: false,
  });
  const calenderData = useCalendarData();

  const locales = {
    "nl-BE": nl,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const onSelectSlot = ({ action, slots }) => {
    if (action === "click") {
      setState({
        ...state,
        openAdd: !state.openAdd,
        selectedDate: slots.toString(),
      });
    }
    return false;
  };

  const eventStyleGetter = () => {
    var backgroundColor = "red";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "10px",
      opacity: 0.8,
      color: "white",
      display: "block",
      textAlign: "center",
      fontWeight: 500,
    };
    return {
      style: style,
    };
  };

  const formValidation = Yup.object().shape({
    name: Yup.string().required("Naam  is een verplicht veld"),
  });

  return (
    <>
      <ConcertsContainer id="calendar" marginTop lightBg>
        <CustomWrapper>
          <GlobalH1>
            Kalender
            <ConcertsAddButton
              onClick={() =>
                setState({
                  ...state,
                  openInfo: !state.openInfo,
                })
              }
            >
              <MdInfoOutline size={40} style={{ color: "#d3b456" }} />
            </ConcertsAddButton>
          </GlobalH1>
          <ConcertsCard
            isWhite={state.openAdd ? false : true}
            isBlack={state.openAdd ? false : true}
          >
            {state.openAdd ? (
              <>
                <ConcertsButtonContainer>
                  <ConcertsAddButton
                    onClick={() =>
                      setState({
                        ...state,
                        openAdd: !state.openAdd,
                      })
                    }
                  >
                    <MdArrowBack size={30} style={{ color: "#cccc" }} />
                    <p style={{ color: "#d3b456", margin: "0px 0px 0px 5px" }}>
                      Terug naar Kalender
                    </p>
                  </ConcertsAddButton>
                </ConcertsButtonContainer>
                <GlobalCalenderH2>
                  Niet beschikbaar op:{" "}
                  <Moment format="DD-MM-YYYY">{state.selectedDate}</Moment>
                </GlobalCalenderH2>
                <Formik
                  initialValues={{
                    name: "",
                    date: state.selectedDate,
                  }}
                  validationSchema={formValidation}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      toast.success("Toegevoegd aan kalender");
                      fire.firestore().collection("calendar").add({
                        title: values.name,
                        allDay: true,
                        start: values.date,
                        end: values.date,
                      });
                      actions.setSubmitting(false);
                      actions.resetForm();
                      setState({ ...state, openAdd: !state.openAdd });
                    }, 1500);
                  }}
                >
                  {(props) => (
                    <>
                      <form
                        style={{ display: "flex", flexDirection: "column" }}
                        autoComplete="off"
                        onSubmit={props.handleSubmit}
                      >
                        <ConcertsFormLabel htmlFor="name">
                          {props.errors.name && props.touched.name ? (
                            <ConcertsErrors>{props.errors.name}</ConcertsErrors>
                          ) : (
                            ""
                          )}
                        </ConcertsFormLabel>
                        <ConcertsFormSelect
                          name="name"
                          defaultValue=""
                          onBlur={props.handleBlur}
                          value={props.values.name}
                          onChange={props.handleChange}
                        >
                          <option hidden value="">
                            Selecteer uw naam
                          </option>
                          <option value="Chris Somers">Chris Somers</option>
                          <option value="Claude Delatter">
                            Claude Delatter
                          </option>
                          <option value="Jacques 'james' Audenaert">
                            Jacques 'james' Audenaert
                          </option>
                          <option value="Lieven D’hondt">Lieven D’hondt</option>
                          <option value="Steven D'hondt">Steven D’hondt</option>
                        </ConcertsFormSelect>
                        <ConcertsFormButtonContainer>
                          <ConcertsFormButton
                            type="submit"
                            disabled={props.isSubmitting}
                          >
                            {props.isSubmitting ? "Even geduld..." : "Doorgaan"}
                          </ConcertsFormButton>
                        </ConcertsFormButtonContainer>
                      </form>
                    </>
                  )}
                </Formik>
              </>
            ) : (
              <Calendar
                style={{
                  minHeight: 700,
                }}
                selectable
                views={["month"]}
                localizer={localizer}
                culture={"nl-BE"}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                onSelectEvent={(val) => {
                  toast.info("Verwijderd uit kalender");
                  fire.firestore().collection("calendar").doc(val.id).delete();
                }}
                defaultDate={new Date()}
                events={calenderData}
                eventPropGetter={eventStyleGetter}
                onSelectSlot={onSelectSlot}
                components={{
                  dateCellWrapper: (props) => (
                    <TouchCellWrapper {...props} onSelectSlot={onSelectSlot} />
                  ),
                }}
                messages={{
                  next: "Volgende",
                  previous: "Vorige",
                  today: "Vandaag",
                  month: "Maand",
                }}
              />
            )}
          </ConcertsCard>
        </CustomWrapper>
        <Modal
          open={state.openInfo}
          onClose={() => setState({ ...state, openInfo: !state.openInfo })}
          center
          showCloseIcon={false}
        >
          <GlobalModalContainer>
            <GlobalModalH1>Info kalender</GlobalModalH1>
            <GlobalText>
              1. Toevoegen: klik in de kalender op de datum dat je niet
              beschikbaar bent voor een concertboeking en selecteer vervolgens
              jouw naam
              <br></br>
              <br></br>
              2. Verwijderen: klik in de kalender op jouw naam en datum waar je
              wel beschikbaar bent voor een concertboeking
            </GlobalText>
          </GlobalModalContainer>
        </Modal>
      </ConcertsContainer>
      <ToastContainer />
    </>
  );
};

export default CustomCalendar;
