import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CookieConsent from "react-cookie-consent";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <CookieConsent
      location="bottom"
      buttonText="Begrepen!"
      cookieName="THE_CROWD_COOCKIES"
      style={{
        background: "#d3b456",
        textAlign: "left",
        fontSize: "14px",
        color: "black",
        fontWeight: 700,
      }}
      buttonStyle={{
        color: "white",
        fontSize: "15px",
        backgroundColor: "#000000",
        borderRadius: "5px",
        padding: "6px 12px",
      }}
      expires={365}
    >
      Deze website maakt gebruik van cookies om de gebruikerservaring te
      verbeteren.
    </CookieConsent>
  </React.StrictMode>,
  document.getElementById("root")
);
