import React from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavButton,
  NavButtonLink,
} from "./navbarElements";
import logo from "../../images/logos/logo_small.png";

const NavbarLogin = ({ handleIsOpen, handleLogout }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav id="navbar_login" scrollNav={true.toString()}>
        <NavbarContainer>
          <NavLogo to="/login" onClick={toggleHome}>
            <img src={logo} height={80} width={112} alt="The Crowd" />
          </NavLogo>
          <MobileIcon onClick={handleIsOpen}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="calendar"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Kalender
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="stavaza"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Stavaza
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="addConcerts"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Concerts
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="records"
                smooth={true}
                duration={900}
                spy={true}
                exact="true"
                offset={-80}
              >
                Records
              </NavLinks>
            </NavItem>
            <NavButton>
              <NavButtonLink onClick={handleLogout}>Logout</NavButtonLink>
            </NavButton>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavbarLogin;
