import styled from "styled-components";

export const GlobalContainer = styled.div`
  color: #fff;

  margin-top: ${({ marginTop }) => (marginTop ? "80px" : "0px")};
  background: ${({ lightBg }) => (lightBg ? "#0c0c0c" : "#000")};

  @media screen and (max-width: 768px) {
    padding: ${({ noPadding }) => (noPadding ? "0" : "50px 0px 50px 0px")};
  }
`;

export const GlobalWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const GlobalModalRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GlobalModalColumn = styled.div`
  flex: 100%;
`;

export const GlobalCard = styled.div`
  border-style: solid;
  border-color: #acacac;
  border-width: thin;
  display: grid;
  border-radius: 10px;
  margin: 12px 0px 45px 0px;
  padding: 15px;
  width: 100%;
  max-width: 1100px;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 12px 0px 0px 0px;
  }
`;

export const BtnContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const BtnModal = styled.a`
  border-radius: 50px;
  background: #000;
  white-space: nowrap;
  padding: 15px 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: grab;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: transparent;
    color: #000;
  }
`;

export const GlobalCardModal = styled.div`
  border-style: solid;
  border-color: #000;
  background-color: #d3b456;
  border-width: thin;
  display: grid;
  border-radius: 10px;
  margin: 0px 0px 18px 0px;
  padding: 15px;
  width: 100%;
  max-width: 1100px;
  align-items: center;
`;

export const GlobalH1 = styled.h1`
  font-size: 2.5rem;
  color: #acacac;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0px 32px 0px;

  @media screen and (max-width: 768px) {
    margin: 0px 0px 64px 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 0px;
  }
`;

export const GlobalH2 = styled.h2`
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  margin-bottom: 45px;
  margin-top: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;
`;

export const GlobalCalenderH2 = styled.h2`
  font-size: 2rem;
  color: #d3b456;
  width: 100%;
  text-align: center;
  margin: 45px 0px 30px 0px;

  @media screen and (max-width: 480px) {
    font-size: 1.7rem;
    margin-top: 0px;
  }
`;

export const GlobalText = styled.div`
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const GlobalModalContainer = styled.div`
  font-size: 16px;
  width: 100%;
  background: #d3b456;
  z-index: 1;
  display: grid;
  border-radius: 10px;
  padding: 26px;
`;

export const GlobalModalContainerSecond = styled.div`
  font-size: 16px;
  width: 100%;
  z-index: 1;
  display: grid;
  border-radius: 10px;
`;

export const GlobalModalH1 = styled.h1`
  margin-bottom: 36px;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

export const GlobalModalH1Second = styled.h1`
  margin-bottom: 36px;
  color: #d3b456;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

export const GlobalP = styled.p`
  font-size: 12px;
  color: #0c0c0c;
`;

export const ErrorContainer = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: black;
`;

export const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;s
`;

export const ErrorText = styled.h1`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  margin-bottom: 45px;
  margin-top: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;
`;
