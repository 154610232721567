import Gallery from "react-grid-gallery";

export default function ImportGallery({ maxRows, images }) {
  return (
    <Gallery
      images={images}
      enableImageSelection
      showImageCount={false}
      maxRows={maxRows}
    />
  );
}
