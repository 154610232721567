import styled from "styled-components";

export const CarouselContainer = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#0c0c0c" : "#000")};
`;

export const CarouselImage = styled.img`
  height: "100%";
  max-height: 850px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;

export const CarouselTextContainer = styled.div``;

export const CarouselTextWrapper = styled.div`
  display: grid;
  padding: 25px;
  width: 100%;
  max-width: 1100px;
  font-size: 17px;
  color: #acacac;
  z-index: 1;
  line-height: 28px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const CarouselTextWrapperLeft = styled.div`
  display: grid;

  padding: 25px 0px;
  width: 100%;
  max-width: 1100px;
  font-size: 17px;
  color: #acacac;
  z-index: 1;
  line-height: 28px;
  height: auto;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
