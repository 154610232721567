import React from "react";
import { saveAs } from "file-saver";

import { ButtonToExterLink, ButtonToLink } from "../ButtonElement";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ButtonWrap,
  Column2,
  ImgWrap,
  Img,
} from "./InfoElements";

import img1Import from "../../images/icons/color.jpg";
import img2Import from "../../images/icons/black.jpg";
import infoDoc from "../../documents/Hoodies.docx";
import JSZip from "jszip";
import { MemberLink } from "../Members/membersElements";

const InfoSection = ({
  id,
  lightBg,
  lightText,
  topLine,
  headLine,
  description,
  buttonLabel,
  imgStart,
  target,
  img,
  noButton,
  href,
  alt,
  darkText,
  customColumn,
  contentColumn,
  buttonLink,
  buttonPath,
  marginTop,
  reversed,
  noTopline,
  customDesc,
  downloadBtn,
  customDesc2,
  href2,
  buttonLabel2,
}) => {
  const handleDownloadAndOpen = (event) => {
    event.preventDefault();

    const fileUrl = infoDoc;
    const fileName = "Hoodies.docx";

    saveAs(fileUrl, fileName, { autoBom: true });
  };
  const handleDownload = async (event) => {
    event.preventDefault();

    const zip = new JSZip();
    const img1 = await fetch(img1Import).then((response) => response.blob());
    const img2 = await fetch(img2Import).then((response) => response.blob());

    zip.file("Template_color.jpg", img1);
    zip.file("Template_blackwhite.jpg", img2);

    const content = await zip.generateAsync({ type: "blob" });

    const link = document.createElement("a");
    link.download = "THECROWD_TEMPLATES.zip";
    link.href = URL.createObjectURL(content);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {!reversed ? (
        <InfoContainer id={id} lightBg={lightBg} marginTop={marginTop}>
          <InfoWrapper>
            <InfoRow imgStart={imgStart}>
              <Column1>
                <TextWrapper>
                  {!noTopline && <TopLine>{topLine}</TopLine>}
                  {!customDesc && (
                    <Heading lightText={lightText}>{headLine}</Heading>
                  )}
                  <Subtitle darkText={darkText}>
                    {customDesc ? (
                      <>
                        Je kan deze template (zwart/wit of kleur) importeren in
                        Paint of Word of een grafisch programma om jouw
                        informatie toe te voegen. Vermeld datum, uur, plaats,
                        prijs, organisatie,…in een tekstvak bovenop deze
                        template.
                      </>
                    ) : customDesc2 ? (
                      <>
                        Klik op "Hoodie bestellen" en geef uw gegevens door! (
                        Klik&nbsp;
                        <MemberLink
                          href="#"
                          onClick={handleDownloadAndOpen}
                          primary={true.toString()}
                        >
                          hier
                        </MemberLink>
                        &nbsp;voor meer info )
                      </>
                    ) : (
                      description
                    )}
                  </Subtitle>

                  <ButtonWrap>
                    {buttonLink ? (
                      <ButtonToLink
                        to={buttonPath}
                        target={target}
                        primary={true.toString()}
                      >
                        {buttonLabel}
                      </ButtonToLink>
                    ) : noButton ? (
                      <></>
                    ) : downloadBtn ? (
                      <ButtonToExterLink
                        href="#"
                        onClick={handleDownload}
                        primary={true.toString()}
                      >
                        {buttonLabel}
                      </ButtonToExterLink>
                    ) : (
                      <ButtonToExterLink
                        href={href}
                        target={target}
                        primary={true.toString()}
                      >
                        {buttonLabel}
                      </ButtonToExterLink>
                    )}
                  </ButtonWrap>
                </TextWrapper>
              </Column1>
              {customColumn ? (
                <Column2>
                  <ImgWrap>{contentColumn}</ImgWrap>
                </Column2>
              ) : (
                <Column2>
                  <ImgWrap>
                    <Img src={img} alt={alt} />
                  </ImgWrap>
                </Column2>
              )}
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
      ) : (
        <InfoContainer id={id} lightBg={lightBg} marginTop={marginTop}>
          <InfoWrapper>
            <InfoRow imgStart={imgStart}>
              {customColumn ? (
                <Column2>
                  <ImgWrap>{contentColumn}</ImgWrap>
                </Column2>
              ) : (
                <Column2>
                  <ImgWrap>
                    <Img src={img} alt={alt} />
                  </ImgWrap>
                </Column2>
              )}
              <Column1>
                <TextWrapper>
                  <TopLine>{topLine}</TopLine>
                  <Heading lightText={lightText}>{headLine}</Heading>
                  <Subtitle darkText={darkText}>{description}</Subtitle>
                  <ButtonWrap>
                    {buttonLink ? (
                      <ButtonToLink
                        to={buttonPath}
                        target={target}
                        primary={true.toString()}
                      >
                        {buttonLabel}
                      </ButtonToLink>
                    ) : noButton ? (
                      <></>
                    ) : (
                      <ButtonToExterLink
                        href={href}
                        target={target}
                        primary={true.toString()}
                      >
                        {buttonLabel}
                      </ButtonToExterLink>
                    )}
                  </ButtonWrap>
                </TextWrapper>
              </Column1>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
      )}
    </>
  );
};

export default InfoSection;
