import React, { useState } from "react";
import { IoMdClock } from "react-icons/io";
import { FaMapPin } from "react-icons/fa";
import {
  MdDeleteForever,
  MdAddCircle,
  MdArrowBack,
  MdEdit,
} from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import Moment from "react-moment";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fire from "../../firebase";

import "../addConcerts/datePicker.css";
import { GlobalH1 } from "../GlobalElements";
import {
  ConcertsList,
  ConcertsListItem,
  ConcertsListDate,
  ConcertsListItemText,
  ConcertsListItemTime,
  ConcertsListItemPlace,
  ConcertsBottomListItem,
  ConcertsCard,
  ConcertsContainer,
  ConcertsWrapper,
  ConcertsDeleteButton,
  ConcertsAddButton,
  ConcertsButtonContainer,
  ConcertsFormLabel,
  ConcertsFormInput,
  ConcertsErrors,
  ConcertsFormButtonContainer,
  ConcertsFormButton,
} from "../Concerts/concertsElements";
import { MemberLink } from "../Members/membersElements";
import {
  formattedDate,
  formattedTime,
  useStavazaData,
} from "../../functions/general";
registerLocale("nl", nl);

const Stavaza = () => {
  const [state, setState] = useState({
    openAdd: false,
    openDelete: false,
    openEdit: false,
    selectedItems: undefined,
  });
  const concertsData = useStavazaData();

  const formValidation = Yup.object().shape({
    name: Yup.string().required("Naam is een verplicht veld"),
    location: Yup.string(),
    startDate: Yup.date("Ongeldige invoer").required(
      "Datum is een verplicht veld"
    ),
    startTime: Yup.date("Ongeldige invoer").required(
      "Starttijd is een verplicht veld"
    ),
    endTime: Yup.date(),
  });

  return (
    <>
      <ConcertsContainer id="stavaza">
        <ConcertsWrapper>
          <GlobalH1>Open opties voor concerten</GlobalH1>
          <ConcertsCard>
            <ConcertsButtonContainer>
              {state.openAdd ? (
                <ConcertsAddButton
                  onClick={() =>
                    setState({
                      ...state,
                      openAdd: !state.openAdd,
                    })
                  }
                >
                  <MdArrowBack size={30} style={{ color: "#cccc" }} />
                  <p style={{ color: "#d3b456", margin: "0px 0px 0px 5px" }}>
                    Terug naar open opties voor concerten
                  </p>
                </ConcertsAddButton>
              ) : state.openEdit ? (
                <ConcertsAddButton
                  onClick={() =>
                    setState({
                      ...state,
                      openEdit: !state.openEdit,
                    })
                  }
                >
                  <MdArrowBack size={30} style={{ color: "#cccc" }} />
                  <p style={{ color: "#d3b456", margin: "0px 0px 0px 5px" }}>
                    Terug naar open opties voor concerten
                  </p>
                </ConcertsAddButton>
              ) : (
                <ConcertsAddButton
                  onClick={() =>
                    setState({ ...state, openAdd: !state.openAdd })
                  }
                >
                  <MdAddCircle size={30} style={{ color: "green" }} />
                  <p style={{ color: "#cccc", margin: "0px 0px 0px 5px" }}>
                    Optreden toevoegen
                  </p>
                </ConcertsAddButton>
              )}
            </ConcertsButtonContainer>
            {state.openAdd ? (
              <Formik
                initialValues={{
                  startDate: "",
                  startTime: "",
                  endTime: "",
                  name: "",
                  location: "",
                  sponsorLink: "",
                }}
                validationSchema={formValidation}
                onSubmit={(values, actions) => {
                  values.endTime === ""
                    ? setTimeout(() => {
                        toast.success("Nieuw optreden toegevoegd");
                        fire.firestore().collection("stavaza").add({
                          startDate: values.startDate,
                          startTime: values.startTime,
                          name: values.name,
                          location: values.location,
                          sponsorLink: values.sponsorLink,
                        });
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setState({ ...state, openAdd: !state.openAdd });
                      }, 1500)
                    : setTimeout(() => {
                        toast.success("Nieuw optreden toegevoegd");
                        fire.firestore().collection("stavaza").add({
                          startDate: values.startDate,
                          startTime: values.startTime,
                          endTime: values.endTime,
                          name: values.name,
                          location: values.location,
                          sponsorLink: values.sponsorLink,
                        });
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setState({ ...state, openAdd: !state.openAdd });
                      }, 1500);
                }}
              >
                {(props) => (
                  <>
                    <form
                      style={{ display: "flex", flexDirection: "column" }}
                      autoComplete="off"
                      onSubmit={props.handleSubmit}
                    >
                      <ConcertsFormLabel htmlFor="name">
                        {props.errors.name && props.touched.name ? (
                          <ConcertsErrors>{props.errors.name}</ConcertsErrors>
                        ) : (
                          "Naam van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="name"
                        placeholder="Vul hier de naam in..."
                        onBlur={props.handleBlur}
                        value={props.values.name}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="location">
                        {props.errors.location && props.touched.location ? (
                          <ConcertsErrors>
                            {props.errors.location}
                          </ConcertsErrors>
                        ) : (
                          "Locatie van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="location"
                        placeholder="Vul hier de locatie in..."
                        onBlur={props.handleBlur}
                        value={props.values.location}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="sponsorLink">
                        {props.errors.sponsorLink &&
                        props.touched.sponsorLink ? (
                          <ConcertsErrors>
                            {props.errors.sponsorLink}
                          </ConcertsErrors>
                        ) : (
                          "Link naar ticketverkoop (exact path invullen: https://...)"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="sponsorLink"
                        placeholder="Vul hier de link in..."
                        onBlur={props.handleBlur}
                        value={props.values.sponsorLink}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="startDate">
                        {props.errors.startDate && props.touched.startDate ? (
                          <ConcertsErrors>
                            {props.errors.startDate}
                          </ConcertsErrors>
                        ) : (
                          "Datum van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="startDate"
                        locale="nl"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Selecteer een datum..."
                        todayButton="Vandaag"
                        onBlur={props.handleBlur}
                        value={props.values.startDate}
                        onChange={(date) =>
                          props.setFieldValue("startDate", date)
                        }
                        selected={props.values.startDate}
                      />
                      <ConcertsFormLabel htmlFor="startTime">
                        {props.errors.startTime && props.touched.startTime ? (
                          <ConcertsErrors>
                            {props.errors.startTime}
                          </ConcertsErrors>
                        ) : (
                          "Starttijd van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="startTime"
                        placeholderText="Selecteer een starttijd..."
                        showTimeSelect
                        locale="nl"
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onBlur={props.handleBlur}
                        value={props.values.startTime}
                        onChange={(date) =>
                          props.setFieldValue("startTime", date)
                        }
                        selected={props.values.startTime}
                      />
                      <ConcertsFormLabel htmlFor="endTime">
                        {props.errors.endTime && props.touched.endTime ? (
                          <ConcertsErrors>
                            {props.errors.endTime}
                          </ConcertsErrors>
                        ) : (
                          "Eindtijd van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="endTime"
                        placeholderText="Selecteer een eindtijd..."
                        showTimeSelect
                        locale="nl"
                        showTimeSelectOnly
                        timeIntervals={15}
                        onBlur={props.handleBlur}
                        value={props.values.endTime}
                        onChange={(date) =>
                          props.setFieldValue("endTime", date)
                        }
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        selected={props.values.endTime}
                      />
                      <ConcertsFormButtonContainer>
                        <ConcertsFormButton
                          type="submit"
                          disabled={props.isSubmitting}
                        >
                          {props.isSubmitting ? "Even geduld..." : "Aanmaken"}
                        </ConcertsFormButton>
                      </ConcertsFormButtonContainer>
                    </form>
                  </>
                )}
              </Formik>
            ) : state.openEdit ? (
              <Formik
                initialValues={{
                  startDate: formattedDate(state.selectedItems.startDate),
                  startTime: formattedTime(state.selectedItems.startTime),
                  endTime: formattedTime(state.selectedItems.endTime),
                  name: state.selectedItems.name,
                  location: state.selectedItems.location,
                  sponsorLink: state.selectedItems.sponsorLink,
                }}
                validationSchema={formValidation}
                onSubmit={(values, actions) => {
                  values.endTime === ""
                    ? setTimeout(() => {
                        toast.success("Optreden gewijzigd");
                        fire
                          .firestore()
                          .collection("stavaza")
                          .doc(state.selectedItems.id)
                          .update({
                            startDate: values.startDate,
                            startTime: values.startTime,
                            name: values.name,
                            location: values.location,
                            sponsorLink: values.sponsorLink,
                          });
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setState({ ...state, openEdit: !state.openEdit });
                      }, 1500)
                    : setTimeout(() => {
                        toast.success("Optreden gewijzigd");
                        fire
                          .firestore()
                          .collection("stavaza")
                          .doc(state.selectedItems.id)
                          .update({
                            startDate: values.startDate,
                            startTime: values.startTime,
                            endTime: values.endTime,
                            name: values.name,
                            location: values.location,
                            sponsorLink: values.sponsorLink,
                          });
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setState({ ...state, openEdit: !state.openEdit });
                      }, 1500);
                }}
              >
                {(props) => (
                  <>
                    <form
                      style={{ display: "flex", flexDirection: "column" }}
                      autoComplete="off"
                      onSubmit={props.handleSubmit}
                    >
                      <ConcertsFormLabel htmlFor="name">
                        {props.errors.name && props.touched.name ? (
                          <ConcertsErrors>{props.errors.name}</ConcertsErrors>
                        ) : (
                          "Naam van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="name"
                        placeholder="Vul hier de naam in..."
                        onBlur={props.handleBlur}
                        value={props.values.name}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="location">
                        {props.errors.location && props.touched.location ? (
                          <ConcertsErrors>
                            {props.errors.location}
                          </ConcertsErrors>
                        ) : (
                          "Locatie van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="location"
                        placeholder="Vul hier de locatie in..."
                        onBlur={props.handleBlur}
                        value={props.values.location}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="sponsorLink">
                        {props.errors.sponsorLink &&
                        props.touched.sponsorLink ? (
                          <ConcertsErrors>
                            {props.errors.sponsorLink}
                          </ConcertsErrors>
                        ) : (
                          "Link naar ticketverkoop (exact path invullen: https://...)"
                        )}
                      </ConcertsFormLabel>
                      <ConcertsFormInput
                        type="text"
                        name="sponsorLink"
                        placeholder="Vul hier de link in..."
                        onBlur={props.handleBlur}
                        value={props.values.sponsorLink}
                        onChange={props.handleChange}
                      />
                      <ConcertsFormLabel htmlFor="startDate">
                        {props.errors.startDate && props.touched.startDate ? (
                          <ConcertsErrors>
                            {props.errors.startDate}
                          </ConcertsErrors>
                        ) : (
                          "Datum van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="startDate"
                        locale="nl"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Selecteer een datum..."
                        todayButton="Vandaag"
                        onBlur={props.handleBlur}
                        value={props.values.startDate}
                        onChange={(date) =>
                          props.setFieldValue("startDate", date)
                        }
                        selected={props.values.startDate}
                      />
                      <ConcertsFormLabel htmlFor="startTime">
                        {props.errors.startTime && props.touched.startTime ? (
                          <ConcertsErrors>
                            {props.errors.startTime}
                          </ConcertsErrors>
                        ) : (
                          "Starttijd van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="startTime"
                        placeholderText="Selecteer een starttijd..."
                        showTimeSelect
                        locale="nl"
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        onBlur={props.handleBlur}
                        value={props.values.startTime}
                        onChange={(date) =>
                          props.setFieldValue("startTime", date)
                        }
                        selected={props.values.startTime}
                      />
                      <ConcertsFormLabel htmlFor="endTime">
                        {props.errors.endTime && props.touched.endTime ? (
                          <ConcertsErrors>
                            {props.errors.endTime}
                          </ConcertsErrors>
                        ) : (
                          "Eindtijd van het optreden"
                        )}
                      </ConcertsFormLabel>
                      <DatePicker
                        name="endTime"
                        placeholderText="Selecteer een eindtijd..."
                        showTimeSelect
                        locale="nl"
                        showTimeSelectOnly
                        timeIntervals={15}
                        onBlur={props.handleBlur}
                        value={props.values.endTime}
                        onChange={(date) =>
                          props.setFieldValue("endTime", date)
                        }
                        timeFormat="HH:mm"
                        dateFormat="HH:mm"
                        selected={props.values.endTime}
                      />
                      <ConcertsFormButtonContainer>
                        <ConcertsFormButton
                          type="submit"
                          disabled={props.isSubmitting}
                        >
                          {props.isSubmitting ? "Even geduld..." : "Wijzigen"}
                        </ConcertsFormButton>
                      </ConcertsFormButtonContainer>
                    </form>
                  </>
                )}
              </Formik>
            ) : (
              concertsData.map((item, key) => (
                <ConcertsList key={key}>
                  <ConcertsListItem>
                    <ConcertsListDate>
                      <div>
                        {item.startDate.toDate().toLocaleDateString("nl-NL", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <ConcertsDeleteButton
                          onClick={() =>
                            setState({
                              ...state,
                              openEdit: !state.openEdit,
                              selectedItems: item,
                            })
                          }
                        >
                          <MdEdit
                            size={24}
                            style={{ color: "#acacac", marginLeft: "12px" }}
                          />
                        </ConcertsDeleteButton>
                        <ConcertsDeleteButton
                          onClick={() => {
                            toast.info("Optreden verwijderd");
                            fire
                              .firestore()
                              .collection("stavaza")
                              .doc(item.id)
                              .delete();
                          }}
                        >
                          <MdDeleteForever
                            size={24}
                            style={{ color: "#de0027", marginLeft: "12px" }}
                          />
                        </ConcertsDeleteButton>
                      </div>
                    </ConcertsListDate>
                    <ConcertsListItemText>
                      {item.name === "" ? "-" : item.name}
                      {item.sponsorLink === "" ? null : (
                        <MemberLink href={item.sponsorLink} target="_blank">
                          {" "}
                          <IoTicketSharp size={24} style={{ color: "green" }} />
                        </MemberLink>
                      )}
                    </ConcertsListItemText>
                    <ConcertsBottomListItem>
                      <ConcertsListItemTime>
                        <div style={{ display: "block" }}>
                          <IoMdClock size={20} style={{ marginRight: "5px" }} />
                        </div>
                        {item.endTime
                          ? item.startTime
                              .toDate()
                              .toLocaleTimeString("nl-NL", {
                                hour: "numeric",
                                minute: "numeric",
                              }) +
                            " - " +
                            item.endTime.toDate().toLocaleTimeString("nl-NL", {
                              hour: "numeric",
                              minute: "numeric",
                            })
                          : item.startTime
                              .toDate()
                              .toLocaleTimeString("nl-NL", {
                                hour: "numeric",
                                minute: "numeric",
                              }) +
                            " - " +
                            "N.V.T"}
                      </ConcertsListItemTime>
                      <ConcertsListItemPlace>
                        <div style={{ display: "block" }}>
                          <FaMapPin size={20} style={{ marginRight: "5px" }} />
                        </div>
                        {item.location === "" ? "-" : item.location}
                      </ConcertsListItemPlace>
                    </ConcertsBottomListItem>
                  </ConcertsListItem>
                </ConcertsList>
              ))
            )}
          </ConcertsCard>
        </ConcertsWrapper>
      </ConcertsContainer>
      <ToastContainer />
    </>
  );
};

export default Stavaza;
