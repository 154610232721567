import logo from "../images/carousel/carousel1.jpg";
import banner from "../images/carousel/carousel.jpg";
import logo2 from "../images/carousel/carousel2.jpg";
import logo3 from "../images/carousel/carousel3.jpg";
import logo4 from "../images/carousel/carousel4.jpg";
import logo5 from "../images/carousel/carousel5.jpg";
import logo6 from "../images/carousel/carousel7.jpg";
import sponsors from "../images/sponsors/denbureau.png";
import sponsors3 from "../images/sponsors/mysolar.png";
import sponsors4 from "../images/sponsors/kbc.png";
import sponsors2 from "../images/sponsors/sbebvba.png";
import sponsors5 from "../images/sponsors/lexis.png";
import sponsors6 from "../images/sponsors/alvaro.png";
import sponsors7 from "../images/sponsors/bollies.png";
import sponsors8 from "../images/sponsors/bossaert.png";
import sponsors9 from "../images/sponsors/denhert.png";
import sponsors10 from "../images/sponsors/desmet.png";
import sponsors11 from "../images/sponsors/dudu.png";
import sponsors12 from "../images/sponsors/hula.png";
import sponsors13 from "../images/sponsors/illias.png";
import sponsors14 from "../images/sponsors/kameleon.png";
import sponsors15 from "../images/sponsors/pauwels.png";
import sponsors16 from "../images/sponsors/praxis.png";
import sponsors17 from "../images/sponsors/sportcafe.png";
import sponsors18 from "../images/sponsors/Stadsboerderij.png";
import sponsors19 from "../images/sponsors/Stella.png";

import media1 from "../images/media/media1.jpg";
import media2 from "../images/media/media2.jpg";
import media3 from "../images/media/media3.jpg";
import media6 from "../images/media/media6.jpg";
import media7 from "../images/media/media7.jpg";
import media9 from "../images/media/media9.jpg";
import media10 from "../images/media/media10.jpg";
import media11 from "../images/media/media11.jpg";
import media12 from "../images/media/media12.jpg";
import media13 from "../images/media/media13.jpg";
import media14 from "../images/media/media14.jpg";
import media15 from "../images/media/media15.jpg";
import media16 from "../images/media/media16.jpg";
import media17 from "../images/media/media17.jpg";
import media18 from "../images/media/media18.jpg";
import media19 from "../images/media/media19.jpg";
import media20 from "../images/media/media20.jpg";
import media21 from "../images/media/media21.jpg";
import media22 from "../images/media/media22.jpg";
import member_claude from "../images/members/claude.jpg";
import member_chris from "../images/members/chris.jpg";
import member_jacques from "../images/members/jacques.jpg";
import member_lieven from "../images/members/lieven.jpg";
import member_steven from "../images/members/steven.jpg";
import affiche1 from "../images/references/affiche1.jpg";
import affiche2 from "../images/references/affiche2.jpg";
import affiche3 from "../images/references/affiche3.jpg";
import affiche4 from "../images/references/affiche4.jpg";
import affiche5 from "../images/references/affiche5.jpg";
import affiche6 from "../images/references/affiche6.jpg";
import affiche7 from "../images/references/affiche7.jpg";
import affiche8 from "../images/references/affiche8.jpg";
import affiche9 from "../images/references/affiche9.jpg";
import affiche10 from "../images/references/affiche10.jpg";
import affiche11 from "../images/references/affiche11.jpg";
import affiche12 from "../images/references/affiche12.jpg";
import affiche13 from "../images/references/affiche13.jpg";
import affiche14 from "../images/references/affiche14.jpg";
import goesClassic1 from "../images/classic/goesClassic1.jpg";
import goesClassic2 from "../images/classic/goesClassic2.jpg";
import goesClassic3 from "../images/classic/goesClassic3.jpg";

const width = 250;
const height = 300;
export const ReferenceImages = [
  {
    src: affiche1,
    thumbnail: affiche1,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche1",
  },
  {
    src: affiche2,
    thumbnail: affiche2,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche2",
  },
  {
    src: affiche3,
    thumbnail: affiche3,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche3",
  },

  {
    src: affiche4,
    thumbnail: affiche4,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche4",
  },
  {
    src: affiche5,
    thumbnail: affiche5,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche4",
  },

  {
    src: affiche6,
    thumbnail: affiche6,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche6",
  },
  {
    src: affiche14,
    thumbnail: affiche14,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche14",
  },
  {
    src: affiche7,
    thumbnail: affiche7,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche7",
  },

  {
    src: affiche8,
    thumbnail: affiche8,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche8",
  },

  {
    src: affiche9,
    thumbnail: affiche9,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche9",
  },
  {
    src: affiche10,
    thumbnail: affiche10,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche10",
  },
  {
    src: affiche11,
    thumbnail: affiche11,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche11",
  },
  {
    src: affiche12,
    thumbnail: affiche12,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche12",
  },
  {
    src: affiche13,
    thumbnail: affiche13,
    thumbnailWidth: width,
    thumbnailHeight: height,
    alt: "affiche13",
  },
];

export const MemberImages = [
  {
    src: member_chris,
  },
  {
    src: member_claude,
  },
  {
    src: member_jacques,
  },
  {
    src: member_lieven,
  },
  {
    src: member_steven,
  },
];

export const infoClassicImages = [
  {
    src: goesClassic1,
  },
  {
    src: goesClassic2,
  },
  {
    src: goesClassic3,
  },
];

export const CarouselImages = [
  /*{
    alt: "carousel1",
    src: banner,
  }, */
  {
    alt: "carousel1",
    src: logo,
  },
  {
    alt: "carousel2",
    src: logo2,
  },
  {
    alt: "carousel3",
    src: logo3,
  },
  {
    alt: "carousel4",
    src: logo4,
  },
  {
    alt: "carousel5",
    src: logo5,
  },
  {
    alt: "carousel6",
    src: logo6,
  },
];

export const SponsorImages = [
  {
    alt: "sponsor1",
    src: sponsors,
  },
  {
    alt: "sponsors2",
    src: sponsors2,
  },
  {
    alt: "sponsors3",
    src: sponsors3,
  },
  {
    alt: "sponsors4",
    src: sponsors4,
  },
  {
    alt: "sponsors5",
    src: sponsors5,
  },
  {
    alt: "sponsors6",
    src: sponsors6,
  },
  {
    alt: "sponsors7",
    src: sponsors7,
  },
  {
    alt: "sponsors8",
    src: sponsors8,
  },
  {
    alt: "sponsors9",
    src: sponsors9,
  },
  {
    alt: "sponsors10",
    src: sponsors10,
  },
  {
    alt: "sponsors11",
    src: sponsors11,
  },
  {
    alt: "sponsors12",
    src: sponsors12,
  },
  {
    alt: "sponsors13",
    src: sponsors13,
  },
  {
    alt: "sponsors14",
    src: sponsors14,
  },
  {
    alt: "sponsors15",
    src: sponsors15,
  },
  {
    alt: "sponsors16",
    src: sponsors16,
  },
  {
    alt: "sponsors17",
    src: sponsors17,
  },
  {
    alt: "sponsors18",
    src: sponsors18,
  },
  {
    alt: "sponsors19",
    src: sponsors19,
  },
];

export const SponsorClassicImages = [
  {
    alt: "sponsor1",
    src: sponsors,
  },
  {
    alt: "sponsors2",
    src: sponsors2,
  },
  {
    alt: "sponsors3",
    src: sponsors3,
  },
  {
    alt: "sponsors4",
    src: sponsors4,
  },
  {
    alt: "sponsors5",
    src: sponsors5,
  },
  {
    alt: "sponsors6",
    src: sponsors6,
  },
  {
    alt: "sponsors7",
    src: sponsors7,
  },
  {
    alt: "sponsors8",
    src: sponsors8,
  },
  {
    alt: "sponsors9",
    src: sponsors9,
  },
  {
    alt: "sponsors10",
    src: sponsors10,
  },
  {
    alt: "sponsors11",
    src: sponsors11,
  },
  {
    alt: "sponsors12",
    src: sponsors12,
  },
  {
    alt: "sponsors13",
    src: sponsors13,
  },
  {
    alt: "sponsors14",
    src: sponsors14,
  },
  {
    alt: "sponsors15",
    src: sponsors15,
  },
  {
    alt: "sponsors16",
    src: sponsors16,
  },
  {
    alt: "sponsors17",
    src: sponsors17,
  },
  {
    alt: "sponsors18",
    src: sponsors18,
  },
  {
    alt: "sponsors19",
    src: sponsors19,
  },
];

export const MediaImages = [
  {
    src: media3,
    thumbnail: media3,
    thumbnailWidth: 200,
    thumbnailHeight: 180,
    alt: "media3",
  },
  {
    src: media1,
    thumbnail: media1,
    thumbnailWidth: 250,
    thumbnailHeight: 180,
    alt: "media1",
  },
  {
    src: media6,
    thumbnail: media6,
    thumbnailWidth: 700,
    thumbnailHeight: 180,
    alt: "media6",
  },
  {
    src: media7,
    thumbnail: media7,
    thumbnailWidth: 250,
    thumbnailHeight: 180,
    alt: "media7",
  },
  {
    src: media9,
    thumbnail: media9,
    thumbnailWidth: 250,
    thumbnailHeight: 180,
    alt: "media9",
  },
  {
    src: media10,
    thumbnail: media10,
    thumbnailWidth: 300,
    thumbnailHeight: 180,
    alt: "media10",
  },
  {
    src: media11,
    thumbnail: media11,
    thumbnailWidth: 170,
    thumbnailHeight: 180,
    alt: "media11",
  },
  {
    src: media13,
    thumbnail: media13,
    thumbnailWidth: 130,
    thumbnailHeight: 180,
    alt: "media13",
  },
  {
    src: media12,
    thumbnail: media12,
    thumbnailWidth: 180,
    thumbnailHeight: 180,
    alt: "media12",
  },
  {
    src: media14,
    thumbnail: media14,
    thumbnailWidth: 285,
    thumbnailHeight: 180,
    alt: "media14",
  },
  {
    src: media15,
    thumbnail: media15,
    thumbnailWidth: 130,
    thumbnailHeight: 180,
    alt: "media15",
  },
  {
    src: media16,
    thumbnail: media16,
    thumbnailWidth: 200,
    thumbnailHeight: 180,
    alt: "media16",
  },
  {
    src: media20,
    thumbnail: media20,
    thumbnailWidth: 140,
    thumbnailHeight: 180,
    alt: "media20",
  },
  {
    src: media17,
    thumbnail: media17,
    thumbnailWidth: 135,
    thumbnailHeight: 180,
    alt: "media17",
  },
  {
    src: media18,
    thumbnail: media18,
    thumbnailWidth: 240,
    thumbnailHeight: 180,
    alt: "media18",
  },
  {
    src: media19,
    thumbnail: media19,
    thumbnailWidth: 240,
    thumbnailHeight: 180,
    alt: "media19",
  },
  {
    src: media21,
    thumbnail: media21,
    thumbnailWidth: 270,
    thumbnailHeight: 180,
    alt: "media21",
  },
  {
    src: media22,
    thumbnail: media22,
    thumbnailWidth: 240,
    thumbnailHeight: 180,
    alt: "media22",
  },
  {
    src: media2,
    thumbnail: media2,
    thumbnailWidth: 250,
    thumbnailHeight: 180,
    alt: "media2",
  },
];
