import styled from "styled-components";

export const MediaContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#0c0c0c" : "#000")};

  @media screen and (max-width: 768px) {
    padding: 50px 0px 50px 0px;
  }
`;

export const MediaCard = styled.div`
 border-style: solid;
  border-color: #acacac;
  border-width: thin;
  display: grid;
  border-radius: 10px;
  margin: 45px 0px 45px 0px;
  padding: 15px;
  width: 100%;
  max-width: 1100px;
  align-items: center; 

  @media screen and (max-width: 768px) {
    margin: 0;
    
`;

export const MediaWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const MediaH1 = styled.h1`
  font-size: 2.5rem;
  color: #acacac;
  margin: 64px 0px 0px 0px;

  @media screen and (max-width: 768px) {
    margin: 0px 0px 0px 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 0px;
  }
`;

export const LinkWrapper = styled.div`
  color: #cccc;
  font-size: 1.5rem;
  margin: 0px 0px 32px 0px;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const LinkWrapperCustom = styled.div`
  color: #cccc;
  font-size: 17px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
