import React from "react";

import {
  CarouselContainer,
  CarouselImage,
  CarouselTextWrapper,
  CarouselTextContainer,
} from "./carouselElements";

import ImportCarousel from "../importCarousel";
import {
  LinkWrapper,
  TitleContainer,
  TitleH2,
} from "../References/referencesElement";
import { MemberLink } from "../Members/membersElements";
import SponsorCarousel from "./sponsorCarousel/";

const CustomCarousel = ({ images, sponsorImages }) => {
  return (
    <CarouselContainer id="carousel">
      <>
        <ImportCarousel
          showIndicators
          interval={10000}
          images={images.map((item, key) => (
            <CarouselImage key={key} src={item.src} alt={item.alt} />
          ))}
        />

        <CarouselTextContainer>
          <CarouselTextWrapper>
            <TitleContainer>
              <TitleH2>The Crowd, Classic Rock Coverband</TitleH2>
            </TitleContainer>
            <br />
            The Crowd, opgericht in 1987 door Chris Somers (gitaar), toen nog
            onder de naam “Lonely in the Crowd”, en sedert 1993 met Claude
            Delatter als zanger is niet zomaar een “dertien in een dozijn”
            Coverband.
            <br />
            <br />
            Na de toetreding van Steven D’Hondt (Bas), Jacques “James” Audenaert
            (drums) en Lieven D’Hondt (Keyboards) bereikte The Crowd ongekende
            muzikale hoogtes. Dat bewijst hun Concertreeks van 1000 Concerten
            door de jaren heen, met diverse passages op de Gentse Feesten
            (Groentenmarkt en Sint-Baafsplain) en de Tinekesfeesten te Heule,
            maar ook op het Midsummerfestival in Handzame (op de affiche met
            Kowlier, Gorki en De Kreuners), Grensrock in Menen, De
            Vijfhoekfeesten in Tollembeek (als afsluiter na Stan van Samang,
            Urbanus en de Fanfaar en The Scabs) en tal van andere grote en
            kleinere podia in België, Nederland en zelfs tot in Parijs.
            <br />
            <br />
            The Crowd brengt een muzikaal hoogstaand, stomend “Classic Rock”
            programma van topklassiekers, van iets meer dan 2 uur, met een oog
            voor detail en zweept het publiek op met zijn energieke act en
            présence op het podium.
            <br />
            <br />
            Ervaring, plezier op en voor het podium en een naar perfectie
            strevende muzikaliteit zijn onze grootste troeven op Dorpsfeesten,
            Parkconcerten, Bedrijfsevenementen en zelfs privé-feesten. En dit
            met een groep muzikanten die door de jaren heen hun sporen hebben
            verdiend op diverse podia, niet enkel bij The Crowd, maar ook bij
            tal van andere muziekprojecten of bands.
            <br />
            <br />
            Neem contact voor het vastleggen van een optie of boeking via ons
            contactformulier op deze site.
            <br />
            Wij beschikken over een eigen professionele P.A. installatie, ook
            voor een eventuele support act, indien gewenst.
            <br />
            <br />
            <LinkWrapper>
              Kijk&nbsp;
              <MemberLink
                href="https://music.youtube.com/channel/UCtccMKgO7meZjj3w2006oHw"
                target="_blank"
              >
                hier
              </MemberLink>
              &nbsp;naar een aantal recente live-videoclips van The Crowd
            </LinkWrapper>
          </CarouselTextWrapper>
        </CarouselTextContainer>
        <SponsorCarousel images={sponsorImages} />
      </>
    </CarouselContainer>
  );
};

export default CustomCarousel;
