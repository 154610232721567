import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./pages";
import LoginPage from "./pages/login";
import SymphonicPage from "./pages/symphonic";

export default function App() {
  let homePage = Home;
  let loginPage = LoginPage;
  let symphonicPage = SymphonicPage;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/symphonic" component={symphonicPage} />
        <Route path="/login" component={loginPage} />
        <Route path="/" component={homePage} />
      </Switch>
    </BrowserRouter>
  );
}
