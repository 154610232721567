import React from "react";
import { SemipolarLoading } from "react-loadingg";

import { ErrorContainer } from "./GlobalElements";

export function ErrorLoader({ text }) {
  const color = "#d3b456";
  const size = "large";

  return (
    <ErrorContainer>
      <SemipolarLoading size={size} color={color} />
    </ErrorContainer>
  );
}
