import React from "react";

import { SponsorImage, SponsorContainer } from "./sponsorElements";
import ImportCarousel from "../../importCarousel";

const SponsorCarousel = ({ images }) => {
  return (
    <SponsorContainer>
      <ImportCarousel
        interval={2500}
        images={images.map((item, key) => (
          <SponsorImage key={key} src={item.src} alt={item.alt} />
        ))}
      />
    </SponsorContainer>
  );
};

export default SponsorCarousel;
