import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { Link as LinkRouter } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

export const TopbarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: black;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #d3b456;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: black;
    color: #f3f1f2;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const TopbarWrapper = styled.div`
  color: #d3b456;
`;

export const TopbarMenu = styled.ul`
  display: grid;
  grid-template-column: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

export const TopbarLink = styled(LinkScroll)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #f3f1f2;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: black;
    color: #d3b456;
  }
`;

export const TopbarButtonWrap = styled.div`
  display: flex;

  justify-content: center;
`;

export const TopbarRoute = styled(LinkRouter)`
  border-radius: 50px;
  background: #d3b456;
  white-space: nowrap;
  padding: 16px 64px;
  color: black;
  margin: 24px 0px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: black;
    color: #d3b456;
    font-weight: bold;
  }
`;
