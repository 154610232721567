import React from "react";

import {
  MemberH1,
  MemberCard,
  MemberH2,
  MemberP,
  MemberImg,
  MemberContainer,
  MemberWrapper,
  MemberImgContainer,
  TitleContainer,
  MemberLink,
} from "./membersElements";

const Members = ({ images }) => {
  const loadedImg = images.map((item) => item.src);

  return (
    <>
      <MemberContainer lightBg id="members">
        <MemberWrapper>
          <MemberH1>Members</MemberH1>
          <MemberCard>
            <TitleContainer start={true.toString()}>
              <MemberH2>Chris Somers - Gitaar</MemberH2>
            </TitleContainer>
            <MemberImgContainer>
              <MemberImg
                src={loadedImg[0]}
                start={true.toString()}
                greyScale
                alt="Chris"
              />
              <MemberP>
                Via diverse schoolgroepjes in 1980 bekend geraakt met de groep
                EX-HOLE, toen Belgische muziek nog Belpop heette (1 LP en 4
                singles). Menig optreden gedaan waaronder de Beursschouwburg, De
                Vooruit en de Brielpoort. Verschillende TV en Radio–optredens.
                live optreden voor Radio 2.
                <br />
                <br />
                In 1983 met LOOKIN’ GLASS (voorloper van Family Duck) de
                wedstrijd gewonnen van Joepie en Het Laatste Nieuws (Rock 83).
                (1 single).
                <br />
                <br />
                Tussen 1984 en 1987 met MODERN VISION 125 optredens gedaan
                (waaronder voorprogramma van OMD in de Brielpoort), veel Airplay
                en een 10-tal TV optredens in NL en België gehad met 2 mini-lp’s
                en 2 singles. Een live optreden verzorgd in VRIJAF bij Gust De
                Coster.
                <br />
                <br />
                In augustus 1987 THE CROWD (toen nog Lonely in the Crowd, in
                1990 afgekort tot The Crowd) opgericht en sindsdien 1000
                optredens gedaan met deze groep.
              </MemberP>
            </MemberImgContainer>
            <TitleContainer right={true.toString()}>
              <MemberH2>Claude Delatter - Vocals</MemberH2>
            </TitleContainer>
            <MemberImgContainer>
              <MemberImg
                src={loadedImg[1]}
                right={true.toString()}
                greyScale
                alt="Claude"
              />
              <MemberP>
                Claude is een autodidact, met een beperkte muzikale theoretische
                opleiding. Hij leerde zijn stem correct gebruiken en ontwikkelen
                bij zangpedagoog Peter De Smet in Antwerpen (stemcoach van Bart
                Peeters, Koen Wauters, Yasmine, Ronny en Robert Mosuse,…), die
                later ook coaching werk deed voor “The Voice”.
                <br />
                <br />
                Hij kreeg de zangmicrobe met de paplepel binnen, want zijn vader
                was begin de jaren 60 zanger bij een populair balorkest.
                <br />
                <br />
                Na een passage bij verschillende kleine bandjes, werd hij heel
                even een Bekende Heulenaar (BH) door zijn deelname aan de Hennie
                Huysman Soundmixshow (1989 – KRO) met{" "}
                <MemberLink
                  href="https://www.facebook.com/100000531001769/videos/3753908087970188/"
                  target="_blank"
                >
                  Billy Joëls “Piano Man”
                </MemberLink>
                , zijn finaleplaats in de eerste VTM Soundmixshow (1989) met
                Billy Joëls “Just the way you are” en in 1990 en zijn
                Sabam-prijs voor de “Beste Belgische Vertolker” met zijn eigen
                nummer “Water bij de wijn” in de Baccarabeker in het Casino van
                Middelkerke. Door zijn koppig vasthouden aan zijn eigenheid
                sprongen verschillende concrete bijna-deals met platenfirma’s
                af, en besliste hij semiprofessioneel met muziek bezig te
                blijven.
                <br />
                <br />
                In 1993 kwam hij op de radar bij Chris Somers, die toen een
                nieuwe zanger zocht voor “The Crowd”, eerst tijdelijk, maar hij
                raakte en wilde er niet meer weg. De rest is geschiedenis, maar
                hij is er steevast van overtuigd dat het mooiste moment voor
                “The Crowd” nog moet komen.
                <br />
                <br />
                De creatieve mosterd en inspiratie haalt hij uiteraard bij Billy
                Joel, maar ook bij Led Zeppelin, U2, Toto, Bon Jovi,…
                <br />
                <br />
                Hij is sinds 1993 de leadzanger van The Crowd.
              </MemberP>
            </MemberImgContainer>
            <TitleContainer>
              <MemberH2>Jacques 'James' Audenaert - Drums</MemberH2>
            </TitleContainer>
            <MemberImgContainer>
              <MemberImg
                src={loadedImg[2]}
                greyScale={true.toString()}
                alt="Jacques"
              />
              <MemberP>
                Begon te drummen op zijn 15e en heeft er sindsdien niet meer mee
                opgehouden.
                <br />
                <br />
                Speelde in tal van bands en op heel wat opnames (pop, rock,
                funk, soul, ... zelfs symfonische rock in een ver verleden).
                <br />
                <br />
                Als hij geen drums speelt, vindt James het heerlijk om gitaar te
                spelen en te zingen, of om te fietsen, of om een leuk
                restaurantje te bezoeken…
              </MemberP>
            </MemberImgContainer>
            <TitleContainer>
              <MemberH2>Lieven D’Hondt - Toetsen</MemberH2>
            </TitleContainer>
            <MemberImgContainer>
              <MemberImg
                src={loadedImg[3]}
                right={true.toString()}
                greyScale={true.toString()}
                alt="Lieven"
              />
              <MemberP>
                Lieven volgde de opleiding klassieke piano maar experimenteerde
                tegelijkertijd vrij intensief met allerlei synthesizers en
                sequencers.
                <br />
                <br />
                Buiten het live werk spendeert hij graag tijd in de studio. De
                creatieve mosterd en inspiratie haalt hij bij Toto, Billy Joel,
                Steely Dan, Level 42, Genesis, Pink Floyd, James Taylor,…
                <br />
                <br />
                Sinds 2016 kan hij zijn muzikale ei kwijt bij The Crowd.
              </MemberP>
            </MemberImgContainer>
            <TitleContainer>
              <MemberH2> Steven D’Hondt - Bas</MemberH2>
            </TitleContainer>
            <MemberImgContainer last>
              <MemberImg
                src={loadedImg[4]}
                greyScale={true.toString()}
                alt="Steven"
              />
              <MemberP>
                Eerste keer de bas ter hand genomen op mijn 16-de en ja…
                sindsdien niet meer gelost!
                <br />
                <br />
                Vind het fantastisch verschillende stijlen te spelen, van pop,
                rock, country, skiffle, blues, Ierse folk…tot zelfs de schlagers
                toe.
                <br />
                <br />
                Naast The Crowd hoofdzakelijk bezig met Stampen & Dagen alwaar
                ik de contrabas beroer.
              </MemberP>
            </MemberImgContainer>
          </MemberCard>
        </MemberWrapper>
      </MemberContainer>
    </>
  );
};

export default Members;
