import React from "react";
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarWrapper,
  TopbarMenu,
  TopbarLink,
  TopbarButtonWrap,
  TopbarRoute,
} from "./topbarElements";

const Topbar = ({ isOpen, handleIsOpen }) => {
  // SYMPHONIC ONDER REFERENCES
  /* <TopbarLink
  to="symphonic"
  smooth={true}
  duration={duration}
  spy={true}
  exact="true"
  offset={offset}
  onClick={handleIsOpen}
>
  Symphonic
</TopbarLink> */

  const duration = 1000;
  const offset = -80;
  return (
    <TopbarContainer isOpen={isOpen} onClick={handleIsOpen}>
      <Icon onClick={handleIsOpen}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink
            to="members"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Members
          </TopbarLink>
          <TopbarLink
            to="concerts"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Concerts
          </TopbarLink>
          <TopbarLink
            to="promo"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Promo
          </TopbarLink>
          <TopbarLink
            to="media"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Media
          </TopbarLink>
          <TopbarLink
            to="references"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            References
          </TopbarLink>
          <TopbarLink
            to="merchandise"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Merchandise
          </TopbarLink>
          <TopbarLink
            to="contact"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Contact
          </TopbarLink>
        </TopbarMenu>
        <TopbarButtonWrap>
          <TopbarRoute to="/login">Login</TopbarRoute>
        </TopbarButtonWrap>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default Topbar;
