import React from "react";
import { IoMdClock } from "react-icons/io";
import { FaMapPin } from "react-icons/fa";

import {
  ConcertsContainer,
  ConcertsWrapper,
  ConcertsH1,
  ConcertsCard,
  ConcertsList,
  ConcertsListItem,
  ConcertsListDate,
  ConcertsListItemText,
  ConcertsListItemTime,
  ConcertsListItemPlace,
  ConcertsBottomListItem,
} from "./concertsElements";
import { ButtonToExterLinkConcerts } from "../ButtonElement";
import { LinkWrapper } from "../Media/mediaElements";

const Concerts = ({ data }) => {
  const currentDateInSeconds = Math.floor(Date.now() / 1000);
  const currentDatePlus24HoursInSeconds = currentDateInSeconds - 86400;

  const filteredEvents = data?.filter((event) => {
    const startDate = event?.startDate?.seconds;

    return startDate > currentDatePlus24HoursInSeconds;
  });

  return (
    <ConcertsContainer id="concerts">
      <ConcertsWrapper>
        <ConcertsH1>Concerts</ConcertsH1>
        <LinkWrapper>
          Voor details en een eventuele tickets bij een betalend concert, klik
          op de knop
          <ButtonToExterLinkConcerts fontSmall primary={true.toString()}>
            INFO
          </ButtonToExterLinkConcerts>
        </LinkWrapper>
        <ConcertsCard>
          {filteredEvents?.map((item, key) => (
            <ConcertsList key={key}>
              <ConcertsListItem>
                <ConcertsListDate>
                  <div>
                    {item.startDate.toDate().toLocaleDateString("nl-NL", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </div>
                  {item.sponsorLink === "" ? null : (
                    <ButtonToExterLinkConcerts
                      href={item.sponsorLink}
                      target="_blank"
                      primary={true.toString()}
                    >
                      INFO
                    </ButtonToExterLinkConcerts>
                  )}
                </ConcertsListDate>
                <ConcertsListItemText>
                  {item.name === "" ? "-" : item.name}
                </ConcertsListItemText>
                <ConcertsBottomListItem>
                  <ConcertsListItemTime>
                    <div style={{ display: "block" }}>
                      <IoMdClock size={20} style={{ marginRight: "5px" }} />
                    </div>
                    {item.startTime === ""
                      ? "-"
                      : item.startTime.toDate().toLocaleTimeString("nl-NL", {
                          hour: "numeric",
                          minute: "numeric",
                        }) +
                        " - " +
                        item.endTime.toDate().toLocaleTimeString("nl-NL", {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                  </ConcertsListItemTime>
                  <ConcertsListItemPlace>
                    <div style={{ display: "block" }}>
                      <FaMapPin size={20} style={{ marginRight: "5px" }} />
                    </div>
                    {item.location === "" ? "-" : item.location}
                  </ConcertsListItemPlace>
                </ConcertsBottomListItem>
              </ConcertsListItem>
            </ConcertsList>
          ))}
        </ConcertsCard>
      </ConcertsWrapper>
    </ConcertsContainer>
  );
};

export default Concerts;
