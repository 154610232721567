import React, { useState, useEffect } from "react";

import NavbarSympho from "../components/Navbar/NavbarSympho";
import TopbarSympho from "../components/Topbar/TopbarSympho";
import FooterSympho from "../components/Footer/footerSympho";
import SponsorsClassic from "../components/SponsorsClassic";
import InfoClassic from "../components/InfoClassic";
import { ErrorLoader } from "../components/errorLoader";
import { infoClassicImages, SponsorClassicImages } from "../components/Images";
import ScrollToTop, { loadImages } from "../functions/general";

const SymphonicPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [sponsorLoaded, setSponsorLoaded] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    Promise.all(infoClassicImages.map((image) => loadImages(image)))
      .then(() => setInfoLoaded(true))
      .catch((err) =>
        console.log(
          "Failed to load info classic images, CONTACT DEVELOPPER",
          err
        )
      );

    Promise.all(SponsorClassicImages.map((image) => loadImages(image)))
      .then(() => setSponsorLoaded(true))
      .catch((err) =>
        console.log("Failed to load sponsor images, CONTACT DEVELOPPER", err)
      );
  }, []);

  return infoLoaded && sponsorLoaded ? (
    <>
      <ScrollToTop />
      <TopbarSympho isOpen={isOpen} handleIsOpen={() => handleIsOpen()} />
      <NavbarSympho handleIsOpen={() => handleIsOpen()} />
      <InfoClassic images={infoClassicImages} />
      <SponsorsClassic images={SponsorClassicImages} />
      <FooterSympho />
    </>
  ) : (
    <ErrorLoader />
  );
};

export default SymphonicPage;
