import React, { useState, useEffect } from "react";
import fire from "../firebase";

import Login from "../components/Login";
import CustomCalendar from "../components/Calendar";
import NavbarLogin from "../components/Navbar/navbarLogin";
import TopbarLogin from "../components/Topbar/topbarLogin";
import FooterLogin from "../components/Footer/footerLogin";
import InfoSection from "../components/InfoSection";
import { Records } from "../components/InfoSection/Data";
import Stavaza from "../components/Stavaza";
import AddConcerts from "../components/addConcerts";
import ScrollToTop from "../functions/general";

const LoginPage = () => {
  const [user, setUser] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    fire.auth().signOut();
  };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  });

  return (
    <>
      {user ? (
        <>
          <ScrollToTop />
          <TopbarLogin
            isOpen={isOpen}
            handleIsOpen={handleIsOpen}
            handleLogout={handleLogout}
          />
          <NavbarLogin
            handleIsOpen={handleIsOpen}
            handleLogout={handleLogout}
          />
          <CustomCalendar />
          <Stavaza />
          <AddConcerts />
          <InfoSection {...Records} />
          <FooterLogin />
        </>
      ) : (
        <>
          <ScrollToTop />
          <Login />
        </>
      )}
    </>
  );
};

export default LoginPage;
