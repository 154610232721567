import styled from "styled-components";

export const SponsorContainer = styled.div`
  cursor: grab;
`;

export const SponsorImage = styled.img`
  object-fit: contain;
  height: 130px;
  width: auto;
  max-width: 1100px;
`;

export const SponsorClassicImage = styled.img`
  object-fit: contain;
  height: 130px;
  width: auto;
  max-width: 1100px;
`;
