import React from "react";
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarWrapper,
  TopbarMenu,
  TopbarLink,
  TopbarButtonWrap,
  TopbarRoute,
} from "./topbarElements";

const TopbarLogin = ({ isOpen, handleIsOpen, handleLogout }) => {
  const duration = 1000;
  const offset = -80;
  return (
    <TopbarContainer isOpen={isOpen} onClick={handleIsOpen}>
      <Icon onClick={handleIsOpen}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink
            to="calendar"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={offset}
            onClick={handleIsOpen}
          >
            Kalender
          </TopbarLink>
          <TopbarLink
            to="stavaza"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            onClick={handleIsOpen}
          >
            Stavaza
          </TopbarLink>
          <TopbarLink
            to="addConcerts"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            onClick={handleIsOpen}
          >
            Concerts
          </TopbarLink>
          <TopbarLink
            to="records"
            smooth={true}
            duration={duration}
            spy={true}
            exact="true"
            offset={-80}
            onClick={handleIsOpen}
          >
            Records
          </TopbarLink>
        </TopbarMenu>
        <TopbarButtonWrap>
          <TopbarRoute onClick={handleLogout}>Logout</TopbarRoute>
        </TopbarButtonWrap>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default TopbarLogin;
