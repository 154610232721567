import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import Topbar from "../components/Topbar";
import InfoSection from "../components/InfoSection";
import Members from "../components/Members";
import Media from "../components/Media";
import References from "../components/References";
import Footer from "../components/Footer";
import Concerts from "../components/Concerts";
import CustomCarousel from "../components/Carousel";
import {
  Symphonic,
  Contact,
  Merchandise,
} from "../components/InfoSection/Data";
import {
  CarouselImages,
  MemberImages,
  MediaImages,
  ReferenceImages,
  SponsorImages,
} from "../components/Images";

import { ErrorLoader } from "../components/errorLoader";
import ScrollToTop, { loadImages, useConcertsData } from "../functions/general";
import PromoMaterial from "../components/Promo";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [memberLoaded, setMemberLoaded] = useState(false);
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [referenceLoaded, setReferenceLoaded] = useState(false);
  const [sponsorLoaded, setSponsorLoaded] = useState(false);
  const concertsData = useConcertsData();

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    Promise.all(CarouselImages.map((image) => loadImages(image)))
      .then(() => setCarouselLoaded(true))
      .catch((err) =>
        console.log("Failed to load carousel images, CONTACT DEVELOPER", err)
      );

    Promise.all(SponsorImages.map((image) => loadImages(image)))
      .then(() => setSponsorLoaded(true))
      .catch((err) =>
        console.log("Failed to load sponsor images, CONTACT DEVELOPER", err)
      );

    Promise.all(MemberImages.map((image) => loadImages(image)))
      .then(() => setMemberLoaded(true))
      .catch((err) =>
        console.log("Failed to load member images, CONTACT DEVELOPER", err)
      );

    Promise.all(MediaImages.map((image) => loadImages(image)))
      .then(() => setMediaLoaded(true))
      .catch((err) =>
        console.log("Failed to load media images, CONTACT DEVELOPER", err)
      );

    Promise.all(ReferenceImages.map((image) => loadImages(image)))
      .then(() => setReferenceLoaded(true))
      .catch((err) =>
        console.log("Failed to load references images, CONTACT DEVELOPER", err)
      );
  }, []);

  // SYMPHONIC TOEVOEGEN ONDER REFERENCES
  // <InfoSection {...Symphonic} />

  const contentToReturn = () => {
    if (
      carouselLoaded &&
      sponsorLoaded &&
      memberLoaded &&
      concertsData &&
      mediaLoaded &&
      referenceLoaded
    ) {
      return (
        <>
          <ScrollToTop />
          <Topbar isOpen={isOpen} handleIsOpen={() => handleIsOpen()} />
          <Navbar handleIsOpen={() => handleIsOpen()} />
          <CustomCarousel
            images={CarouselImages}
            sponsorImages={SponsorImages}
          />
          <Members images={MemberImages} />
          <Concerts data={concertsData} />
          <PromoMaterial images={ReferenceImages} data={concertsData} />
          <Media images={MediaImages} />
          <References images={ReferenceImages} />
          <InfoSection {...Merchandise} />
          <InfoSection {...Contact} />
          <Footer />
        </>
      );
    } else {
      return <ErrorLoader />;
    }
  };

  return contentToReturn();
};

export default Home;
