import styled from "styled-components";

export const ReferencesTextContainerModal = styled.div`
  display: grid;
  width: 100%;
  max-width: 1100px;
  font-size: 17px;
  color: #000;
  z-index: 1;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  ul {
    padding: 0px 0px 0px 2.3em;
    position: relative;
  }

  ul li {
    line-height: 1.5;
    padding: 0.5em 0;
    list-style-type: none !important;
  }

  ul li:before {
    content: "•";
    position: absolute;
    left: 1em;
    color: #000;
  }
`;

export const ReferencesTextContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1100px;
  font-size: 17px;
  color: #acacac;
  z-index: 1;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  ul {
    padding: 0px 0px 0px 2.3em;
    position: relative;
  }

  ul li {
    line-height: 1.5;
    padding: 0.5em 0;
    list-style-type: none !important;
  }

  ul li:before {
    content: "•";
    position: absolute;
    left: 1em;
    color: #d3b456;
  }
`;

export const LinkWrapper = styled.div`
  text-align: center;
  color: #cccc;
  margin: 16px 8px;
`;
export const ReferencesUL = styled.ul``;

export const ReferencesLI = styled.li``;

export const ReferencesGalleryContainer = styled.div`
  margin-top: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const TitleH2 = styled.h2`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const TitleH2Modal = styled.h2`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const CustomH2 = styled.h2`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 24px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #d3b456;

  @media screen and (max-width: 480px) {
    font-size: 24px;
    margin: 0;
  }
`;
